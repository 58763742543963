var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "flex flex-col px-2 lg:px-4 w-c6 md:w-c4" }, [
    _c(
      "a",
      {
        staticClass:
          "flex flex-col h-full no-underline cursor-pointer hover:underline hover:text-berry group",
        attrs: { href: _vm.entry.url }
      },
      [
        _c(
          "div",
          {
            staticClass:
              "h-full pt-3 pb-2 border-b border-light-grey md:pt-4 sm:pb-4"
          },
          [
            _c("div", { staticClass: "ar-3x2 bg-pink-white" }, [
              _vm.image
                ? _c("img", {
                    staticClass:
                      "absolute block object-cover w-full h-full m-0 transition-opacity group-hover:opacity-75 backface-hidden lazyload",
                    attrs: { src: _vm.image.url }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _c(
              "h2",
              {
                staticClass:
                  "mt-2 mb-0 text-12 sm:text-14 font-sans-bold text-offblack sm:mt-5"
              },
              [_vm._v(_vm._s(_vm.title))]
            )
          ]
        )
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }