var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "a",
      {
        class: _vm.aClass,
        attrs: {
          title: "Share on Facebook",
          target: "_BLANK",
          href: _vm.shareFacebook
        }
      },
      [
        _c("svg-icon", {
          class: _vm.svgClass,
          attrs: { size: "4", icon: "facebook" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "visually-hidden" }, [
          _vm._v("Share on Facebook")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        class: _vm.aClass,
        attrs: {
          title: "Share on Twitter",
          target: "_BLANK",
          href: _vm.shareTwitter
        }
      },
      [
        _c("svg-icon", {
          class: _vm.svgClass,
          attrs: { size: "4", icon: "twitter" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "visually-hidden" }, [
          _vm._v("Share on Twitter")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "focus-mouse:outline-none focus:bg-white",
        class: _vm.aClass,
        attrs: { title: "Copy URL to Clipboard" },
        on: { click: _vm.copy }
      },
      [
        _c("svg-icon", {
          class: _vm.svgClass,
          attrs: { size: "4", icon: "link" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "visually-hidden" }, [
          _vm._v("Copy URL to Clipboard")
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("input", {
      directives: [
        { name: "model", rawName: "v-model", value: _vm.url, expression: "url" }
      ],
      ref: "target",
      staticClass: "visually-hidden",
      attrs: { type: "hidden" },
      domProps: { value: _vm.url },
      on: {
        input: function($event) {
          if ($event.target.composing) {
            return
          }
          _vm.url = $event.target.value
        }
      }
    }),
    _vm._v(" "),
    _vm.failed
      ? _c("div", { staticClass: "mt-1" }, [
          _c("label", { staticClass: "text-11 font-sans-bold" }, [
            _vm._v("Select text and copy to clipboard")
          ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.url,
                expression: "url"
              }
            ],
            ref: "fallback",
            staticClass:
              "w-full mt-2 text-center focus:border-berry text-11 p-2",
            attrs: { type: "text", readonly: "" },
            domProps: { value: _vm.url },
            on: {
              focus: function($event) {
                return _vm.select()
              },
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.url = $event.target.value
              }
            }
          })
        ])
      : _c(
          "div",
          {
            staticClass:
              "w-full text-black text-11 font-sans-bold font-sans-normal mt-1 h-4"
          },
          [
            _vm.output
              ? [_vm._v("\n\t\t\t" + _vm._s(_vm.output) + "\n\t\t")]
              : _vm._e()
          ],
          2
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }