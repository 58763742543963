var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "md:ml-c1", attrs: { id: "project-results-list" } },
    [
      !_vm.atoz
        ? _c("h2", { staticClass: "text-22 tracking-tight font-sans-bold" }, [
            _vm._v("Projects A-Z")
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.query || _vm.shadowCategory
        ? _c(
            "div",
            {
              staticClass:
                "flex mt-1 md:pt-15 lg:pt-12 pt-3px pb-2 border-b-2 border-berry"
            },
            [
              _c(
                "div",
                {
                  staticClass: "font-sans-bold",
                  class: {
                    "lg:text-60 text-48": !_vm.query || !_vm.shadowCategory,
                    "lg:text-48 text-36": _vm.query && _vm.shadowCategory
                  }
                },
                [
                  _vm.query
                    ? _c("span", { staticClass: "inline-block" }, [
                        _c("span", { staticClass: "text-grey" }, [
                          _vm._v("Searched for")
                        ]),
                        _vm._v(" "),
                        _c("em", [_vm._v("'" + _vm._s(_vm.query) + "'")]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            class: _vm.buttonClass,
                            on: {
                              click: function($event) {
                                _vm.query = ""
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "visually-hidden" }, [
                              _vm._v("Clear Search Query")
                            ]),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                staticClass:
                                  "pointer-events-none fill-current svgfull block w-2 h-2",
                                attrs: {
                                  focusable: "false",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#svg-close" }
                                })
                              ]
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _vm.shadowCategory
                          ? _c("span", { staticClass: "text-grey" }, [
                              _vm._v("in")
                            ])
                          : _vm._e()
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.shadowCategory
                    ? _c("span", { staticClass: "inline-block" }, [
                        _c("span", [_vm._v(_vm._s(_vm.shadowCategory.title))]),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            class: _vm.buttonClass,
                            on: {
                              click: function($event) {
                                _vm.category = false
                              }
                            }
                          },
                          [
                            _c("span", { staticClass: "visually-hidden" }, [
                              _vm._v("Clear Category Filter")
                            ]),
                            _vm._v(" "),
                            _c(
                              "svg",
                              {
                                staticClass:
                                  "pointer-events-none fill-current svgfull block w-2 h-2",
                                attrs: {
                                  focusable: "false",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("use", {
                                  attrs: { "xlink:href": "#svg-close" }
                                })
                              ]
                            )
                          ]
                        )
                      ])
                    : _vm._e()
                ]
              )
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "relative" },
        [
          _c(
            "loading-results",
            { attrs: { loading: _vm.loading } },
            [
              _vm.loading
                ? [_vm._v("\n\t\t\t\tLoading Projects...\n\t\t\t")]
                : [
                    _vm._v(
                      "\n\t\t\t\tFinished Loading. " +
                        _vm._s(_vm.projects.length) +
                        " Projects Found.\n\t\t\t"
                    )
                  ]
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "ul",
            { staticClass: "list-reset flex flex-wrap -mx-2 lg:-mx-4 pt-2" },
            [
              _vm._l(_vm.projects, function(project) {
                return _c("project-preview", {
                  key: project.id,
                  attrs: { entry: project }
                })
              }),
              _vm._v(" "),
              _vm.projects.length === 0
                ? _c("li", [
                    _c("div", { staticClass: "p-4" }, [
                      _vm._v("No projects found. Try widening your search")
                    ])
                  ])
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }