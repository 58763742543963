import Slideshow from "@/libs/slideshow";
import { docReady, qsa } from "@/libs/helpers";
import { media } from "@/parts/media";

docReady(() => {
    const heroSlideshow = document.querySelector('[data-slideshow="hero"]');

    if (heroSlideshow) {
        let container = heroSlideshow;
        let slide = '[data-slide]';
        let ss = new Slideshow({
            container,
            slide,
        });

        ss.options = {
            duration: 12000,
            z: 1,
            playNextAt: 5000,
            fadeInDelay:  1000,
            fadeOutDelay: 1000,
            fadeDuration: 1000,
        };

        window.ss = ss;
        ss.start();
    }
});
