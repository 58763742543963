<template>

		<div>

			<a
				title="Share on Facebook"
				target="_BLANK"
				:href="shareFacebook"
				:class="aClass">
				<svg-icon :class="svgClass" size="4" icon="facebook"/>
				<span class="visually-hidden">Share on Facebook</span>
			</a>

			<a
				title="Share on Twitter"
				target="_BLANK"
				:href="shareTwitter"
				:class="aClass">
				<svg-icon :class="svgClass" size="4" icon="twitter"/>
				<span class="visually-hidden">Share on Twitter</span>
			</a>

			<button
				title="Copy URL to Clipboard"
				@click="copy"
				class="focus-mouse:outline-none focus:bg-white"
				:class="aClass">
				<svg-icon :class="svgClass" size="4" icon="link"/>
				<span class="visually-hidden">Copy URL to Clipboard</span>
			</button>

			<input class="visually-hidden" type="hidden" v-model="url" ref="target" />

			<!-- Copy interface -->
			<div v-if="failed" class="mt-1">
				<label class="text-11 font-sans-bold">Select text and copy to clipboard</label>
				<input ref="fallback" @focus="select()" type="text"
					class="w-full mt-2 text-center focus:border-berry text-11 p-2" readonly v-model="url" />
			</div>
			<div v-else class="w-full text-black text-11 font-sans-bold font-sans-normal mt-1 h-4">
				<template v-if="output">
					{{ output }}
				</template>
			</div>
		</div>


</template>

<script>
import SvgIcon from "@/components/generic/SvgIcon.vue";
import IconButton from "@/components/generic/IconButton.vue";

export default {
	components: {
		SvgIcon,
		IconButton,
	},

	data() {
		return {
			url: window.location.href,
			output: "",
			failed: false,

			aClass: 'mx-1 inline-flex relative w-9 h-9 rounded-full border border-current text-berry bg-white hover:bg-pink-white hover:text-black items-center justify-center group',
			svgClass: 'fill-current relative z-1',
		}
	},

	computed: {
		shareTwitter() {
			return encodeURI(`https://twitter.com/intent/tweet?text=${window.location.href}`);
		},

		shareFacebook() {
			return encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${window.location.href}`);
		},

	},
	methods: {

		select() {
			this.$refs.fallback.select();
		},

		fallback() {
			// Failed, let's show an input and let them copy...
			target.setAttribute('text')
		},

		copy() {
			if ( this.failed ) {
				this.fallback();
				return;
			}

			let target = this.$refs.target;
			target.setAttribute('type', 'text');
			target.select();
			let success;

			try {
				success = document.execCommand('copy');
			} catch (err) {

			}

			if ( success ) {
				this.output = "Link copied to clipboard";
				setTimeout( () => {
					this.output = false;
				}, 2000 );
				/* unselect the range */
				target.setAttribute('type', 'hidden');
				window.getSelection().removeAllRanges();
			} else {
				this.output = false;
				this.failed = true;
				this.fallback();
			}


		}
	}

}
</script>

<style>
</style>
