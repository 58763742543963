var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", { staticClass: "inline-block", class: _vm.classes }, [
    _c(
      "svg",
      {
        staticClass: "svgfull fill-current",
        attrs: { xmlns: "http://www.w3.org/2000/svg" }
      },
      [_c("use", { attrs: { "xlink:href": _vm.xlink } })]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }