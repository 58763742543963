<template>
	<div aria-controls="news-results-list">
		<handorgel-accordion class="border-b border-light-grey" heading="Filter by Topics">
			<ul tabindex="-1" class="list-reset">
				<li :key="topic.id" v-for="topic in topics">
					<button :aria-pressed="topic.id === topicId ? 'true' : 'false'"
							:class="{ 'text-berry': topic.id === topicId, 'text-black': topic.id !== topicId, }"
							class="hover:text-berry text-left focus-mouse:outline-none text-14 md:text-16 hover:underline bg-transparent border-none px-2 py-1"
							@click="filterByTopic( topic )"
							:aria-label="topic.id === topicId ? `Remove Projects filter: ${topic.title}` : `Apply Projects filter: ${topic.title}`"
							type="button">{{ topic.title }}
					</button>
				</li>
			</ul>
		</handorgel-accordion>

		<handorgel-accordion class="border-b border-light-grey" heading="Filter by Authors">
			<ul tabindex="-1" class="list-reset">
				<li :key="author.id" v-for="author in authors">
					<button :aria-pressed="author.id === authorId ? 'true' : 'false'"
							:class="{ 'text-berry': author.id === authorId, 'text-black': author.id !== authorId, }"
							class="hover:text-berry text-left focus-mouse:outline-none text-14 md:text-16 hover:underline bg-transparent border-none px-2 py-1"
							@click="filterByAuthor( author )"
							:aria-label="author.id === authorId ? `Remove Projects filter: ${author.title}` : `Apply Projects filter: ${author.title}`"
							type="button">{{ author.title }}
					</button>
				</li>
			</ul>
		</handorgel-accordion>

		<div class="text-14 md:text-16 relative -my-px">
			<form @submit.prevent="getData">
				<fieldset>
					<legend class="visually-hidden">Search For News</legend>
					<label class="visually-hidden" :for="`${namespace}__searchId`">Search News</label>
					<input @input="updateQuery"
						   placeholder="Search News"
						   :class="{ 'italic': !query, }"
						   class="bg-transparent m-0 px-4 py-5 w-full border-t border-b focus-mouse:outline-none focus-mouse:border-berry border-light-grey"
						   :id="`${namespace}__searchId`"
						   type="text"
						   v-model="query" />
					<button class="absolute text-left top-0 right-0 mt-0 z-4 h-full w-7 mr-3 flex items-center justify-center text-charcoal focus:text-berry hover:text-berry focus-mouse:outline-none"
							aria-label="Submit Search"
							@click="getData">
						<svg focusable="false" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none w-7 h-7 block fill-current"><use xlink:href="#svg-search"></use></svg>
					</button>
				</fieldset>
			</form>
		</div>
	</div>
</template>

<script>

import axios from "axios";
import HandorgelAccordion from "@/components/generic/HandorgelAccordion.vue";

export default {
	store: ['news', 'topics', 'topic', 'authors', 'author', 'topicId', 'authorId', 'loading', 'query', 'runOnce'],
	components: {
		HandorgelAccordion,
	},
	data() {
		return {
			timer: false,
            disableGetData: false,
		}
	},
	computed: {
		namespace() {
			return this.$root.namespace;
		},
	},
	watch: {
		topic() {
			this.getData();
			if ( this.topic ) {
				history.pushState( {}, this.topic.title, this.topic.url );
			} else {
                history.pushState({}, "News", window.VM.allNewsUrl);
            }
		},

        author() {
            this.getData();
            if ( this.author ) {
                history.pushState( {}, this.author.title, this.author.url );
            } else {
                history.pushState({}, "News", window.VM.allNewsUrl);
            }
        }
	},
	created() {
		// Just revert to normal behaviour if history was changed.
		window.onpopstate = () => {
			window.location.href = window.location.href;
		}
	},
	methods: {
        filterByTopic( topic = false ) {
            if (this.author) {
                this.disableGetData = true;
                this.author = false;
            }

			if ( !topic || ( this.topicId == topic.id && topic.id) ) {
				this.topic = false;
			} else {
				this.topic = topic;
			}
		},

        filterByAuthor( author = false ) {
            if (this.topic) {
                this.disableGetData = true;
                this.topic = false;
            }

			if ( !author || ( this.authorId == author.id && author.id) ) {
				this.author = false;
			} else {
				this.topic = false
				this.author = author;
			}
		},

		updateQuery() {
			clearTimeout( this.timer );
			this.loading = true;
			this.timer = setTimeout( () => {
				this.getData();
			}, 500 );
		},
		hideFeatured() {
			this.runOnce = true;

			const fp = document.getElementById('featured-news');
			if ( fp ) {
				// Get rid of featured projects
				fp.remove();
				// move to the top
				window.scrollTo(0,0);
			}
		},
		getData() {

            if (this.disableGetData) {
                this.disableGetData = false;
                return;
            }

			if ( !this.runOnce ) {
				this.hideFeatured();
			}

            const transformString = `width: 1280, height: 720, position: "topLeft", quality: 50, format: "jpg"`;

            let relatedToFilter = '';
			if ( this.topicId ) {
				relatedToFilter = `relatedTo: ${this.topicId},`;
			}

			if ( this.authorId ) {
				relatedToFilter = `relatedTo: ${this.authorId},`;
			}

			const query = `query NewsQuery {
				news: entries(
					section:"news",
					limit: 40,
					${relatedToFilter}
					search:"${this.query.trim()}"
				) {
					title
					url
					id
					postDate
					typeHandle
					... on news_updates_Entry {
						 gridImage(limit:1) {
                            title
                            id
                            width @transform (${transformString})
                            height @transform (${transformString})
                            url @transform (${transformString})
                        }
					}
					... on news_opinion_Entry {
						 gridImage(limit:1) {
                            title
                            id
                            width @transform (${transformString})
                            height @transform (${transformString})
                            url @transform (${transformString})
                        }
					}
				}
			}`;

			const headers = {
				"Authorization": `bearer ${APP.config.gqlToken}`,
                "X-Requested-With": "XMLHttpRequest",
				"Content-type": "application/json",
			};

			this.loading = true;

			axios.post(
					'/api',
					{ query },
					{ headers }
				).then( ({ data: response }) => {
				// Yeah, I know, I know,
				const { data, errors } = response;

				this.loading = false;

				if ( errors ) {
					errors.forEach( error => {
						console.log( error );
					});
					return;
				}

				if ( !data ) {
					console.log("Unknown response");
					return;
				}

				const { news } = data;

				if ( news ) {
					this.news = news;
					return;
				}

				console.log("Unknown response");
				return;

			}).catch( e => {
				console.error( e );
				this.loading = false;
			});
		}
	}

}
</script>

<style>

</style>
