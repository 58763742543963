var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("hr", {
      staticClass:
        "border-none h-1 mb-0 -mt-px relative z-1 -mb-1 transition-background",
      class: { "bg-transparent": !_vm.open, "bg-berry": _vm.open }
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        ref: "container",
        staticClass:
          "handorgel px-4 transition-background h-full max-h-filter overflow-y-auto border-t border-light-grey ",
        class: { "bg-pink-white": _vm.open }
      },
      [
        _c(
          "h3",
          {
            staticClass:
              "handorgel__header text-14 md:text-16 tracking-body w-full items-center flex justify-between"
          },
          [
            _c(
              "button",
              {
                staticClass:
                  "handorgel__header__button py-5 text-left hover:text-berry focus-mouse:outline-none focus-key:border"
              },
              [
                _vm._v("\n\t\t\t\t" + _vm._s(_vm.heading) + "\n\t\t\t\t"),
                _c(
                  "svg",
                  {
                    staticClass:
                      "svgfull absolute right-0 mt-6 w-3 h-3 text-orange fill-current top-0",
                    attrs: {
                      xmlns: "http://www.w3.org/2000/svg",
                      viewBox: "0 0 73.9 44"
                    }
                  },
                  [
                    _c("path", {
                      attrs: {
                        d:
                          "M73.9 6.9c0 1.8-.7 3.6-2 5l-30 30c-2.7 2.7-7.2 2.7-9.9 0l-30-30C-.7 9.2-.7 4.7 2 2s7.2-2.7 9.9 0l25 25 25-25c2.7-2.7 7.2-2.7 9.9 0 1.4 1.3 2.1 3.1 2.1 4.9z"
                      }
                    })
                  ]
                )
              ]
            )
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "handorgel__content w-full text-indigo" }, [
          _c(
            "div",
            {
              staticClass:
                "handorgel__content__inner pt-2 pb-6 leading-normal tracking-tight text-16 pr-6"
            },
            [_vm._t("default")],
            2
          )
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }