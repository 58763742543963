import { docReady, qsa } from "@/libs/helpers";
import Vue from "vue";
import VueStash from "vue-stash";

import AppNews from "@/components/AppNews.vue";
import AppFilters from "@/components/AppNewsFilters.vue";

Vue.use(VueStash);


const mount = () => {


	const filters = document.getElementById('vue-app-newsfilters');
	const news = document.getElementById('vue-app-news');

	if (!(filters && news)) {
		return;
	}

	const store = {
	    news: window.VM.news,
		topics: window.VM.newsTopics,
		authors: window.VM.authors,
		topic: window.VM.newsTopic,
		author: window.VM.author,
		query: '',
        runOnce: false,
		get topicId() {
			if (this.topic) {
				return this.topic.id;
			}

			return false;
		},
        get authorId() {
			if (this.author) {
				return this.author.id;
			}

			return false;
		},
		loading: false,
	};

	// eslint-disable-next-line
	new Vue({
		el: filters,
		data: {
			store,
			namespace: filters.id
		},
		render: h => h(AppFilters),
	});

	// eslint-disable-next-line
	new Vue({
		el: news,
		data: {
			store,
			namespace: news.id
		},
		render: h => h(AppNews),
	});
};

// Test the flash:
// window.mount = mount;

docReady(() => {
	mount();
});
