var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fixed inset-0 z-200 bg-black-80 transition-alpha",
      class: {
        invisible: !_vm.open
      }
    },
    [
      _c(
        "focus-trap",
        {
          staticClass: "absolute inset-0 flex items-center justify-center",
          attrs: { disabled: !_vm.open }
        },
        [
          _c("div", {
            staticClass: "absolute inset-0 z-0 cursor-pointer",
            on: { mouseup: _vm.close }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "relative flex flex-col w-full max-w-5xl px-4 antialiased text-white z-1 sm:flex-row sm:px-0"
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center sm:flex-no-shrink flex-no-grow sm:w-48"
                },
                [
                  _c(
                    "icon-button",
                    {
                      staticClass: "my-2 ml-auto sm:hidden",
                      attrs: { icon: "close" },
                      on: { click: _vm.close }
                    },
                    [
                      _vm._v(
                        "\n                    Dismiss Overlay\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "flex items-center flex-grow" },
                [
                  _c(
                    "icon-button",
                    {
                      staticClass: "flex-shrink-0 mr-4 sm:hidden",
                      attrs: { icon: "arrow-left" },
                      on: { click: _vm.prev }
                    },
                    [
                      _vm._v(
                        "\n                    Previous Image\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "ar-8x5" }, [
                    _c("div", { staticClass: "absolute inset-0" }, [
                      _c(
                        "div",
                        { ref: "carousel", staticClass: "w-full h-full" },
                        _vm._l(_vm.cells, function(img, i) {
                          return _c(
                            "div",
                            {
                              key: "cell-" + i,
                              staticClass: "absolute w-full h-full"
                            },
                            [
                              _c("img", {
                                staticClass: "object-contain",
                                attrs: { src: img.src }
                              })
                            ]
                          )
                        }),
                        0
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c(
                    "icon-button",
                    {
                      staticClass: "flex-shrink-0 ml-4 sm:hidden",
                      attrs: { icon: "arrow-right" },
                      on: { click: _vm.next }
                    },
                    [
                      _vm._v(
                        "\n                    Next Image\n                "
                      )
                    ]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "hidden px-4 sm:block sm:flex-no-shrink flex-no-grow sm:w-48"
                },
                [
                  _c(
                    "icon-button",
                    {
                      staticClass: "mb-12",
                      attrs: { icon: "close" },
                      on: { click: _vm.close }
                    },
                    [_vm._v("\n\t\t\t\t\tDismiss Overlay\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "icon-button",
                    {
                      staticClass: "my-1",
                      attrs: { icon: "arrow-right" },
                      on: { click: _vm.next }
                    },
                    [_vm._v("\n\t\t\t\t\tNext Image\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _c(
                    "icon-button",
                    {
                      staticClass: "my-1",
                      attrs: { icon: "arrow-left" },
                      on: { click: _vm.prev }
                    },
                    [_vm._v("\n\t\t\t\t\tPrevious Image\n\t\t\t\t")]
                  ),
                  _vm._v(" "),
                  _vm.caption.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "py-6 mt-6 leading-normal border-t border-b border-white-20 md:text-18"
                        },
                        [
                          _vm._v(
                            "\n\t\t\t\t\t" + _vm._s(_vm.caption) + "\n\t\t\t\t"
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _vm._v(" "),
              _vm.caption.length
                ? _c(
                    "div",
                    {
                      staticClass:
                        "py-2 mt-2 leading-normal text-center border-t border-b sm:hidden border-white-20"
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.caption) +
                          "\n            "
                      )
                    ]
                  )
                : _vm._e()
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }