import { docReady, qsa } from "@/libs/helpers";
import Vue from "vue";
import ShareWidget from "@/components/generic/ShareWidget.vue";

docReady( () => {

	qsa('[data-share-widget]', el => {

		return new Vue({
			el,
			render: h => h(ShareWidget),
		});
	});
});
