export const GlobalState = {


	active: {
		hamburger: false,
		search: false,
	}

};

window.GlobalState = GlobalState;

export default { GlobalState };