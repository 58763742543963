import { Exposure } from "@/libs/exposure";
import { docReady, qsa } from "@/libs/helpers";

docReady(() => {
	qsa('[data-exposure]', container => {
		/* eslint-disable no-new */
		new Exposure(container, {
			maxHeight: 200,
		});
	});
});
