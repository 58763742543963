
import { docReady, qsa } from "@/libs/helpers";
import { media } from "@/parts/media";
import { GlobalState } from "@/parts/global-state";


window.media = media;

let header;
let locked = false;
let lastScrollY = 0;
let totalMovement = 0;
const max = 200 * 2;
const min = 0;

const limit = function (n) {
	return Math.min(max, Math.max(min, n));
};

let state = "top";

const stuck = () => {
	if (state === "top") {
		state = "stuck";
		header.setAttribute('data-state', "stuck");
	}
};

const unstuck = () => {
	if (state === "stuck") {
		state = "top";
		header.setAttribute('data-state', "top");
	}
};

const stickies = [];
qsa( '.sticky', el => {

	let obj = {
		el,
		intersecting: false
	};

	const observer = new IntersectionObserver((entries, obs) => {
		obj.intersecting = entries[0].isIntersecting;
	}, {
		rootMargin: '0px',
		threshold: 1.0
	});

	observer.observe(el);
	stickies.push(obj);
});

const paint = () => {

	if ( locked ) {
		return;
	}

	let y;

	if ( GlobalState.active.hamburger ) {
		y = 0;
	} else {
		y = -0.5 * totalMovement;
	}

	header.style.transform = `translateY(${y}px)`;

	if (window.pageYOffset > 200 && media.matches('md') ) {
		stuck();
		stickies.forEach( ({ el, intersecting }) => {
			if ( intersecting ) {
				const ny = Math.max( 0, y + 77 );
				el.style.transform = `translateY(${ny}px)`;
				el.style.transition = '';
			}
		});
	} else {
		stickies.forEach( e => {
			e.el.style.transition = 'transform .4s ease';
			e.el.style.transform = `translateY(0px)`;
		});
		unstuck();
	}
};

const scroll = () => {
	totalMovement = limit(totalMovement + (window.pageYOffset - lastScrollY));
	lastScrollY = Math.max(0, window.pageYOffset);
	requestAnimationFrame(paint);
};

export const lockHeader = () => {
	if ( header ) {
		// Trigger stuck / untuck, conditioonally
		paint();
		header.style.transform = `translateY(0px)`;
		locked = true;
	}
};

export const unlockHeader = () => {
	if ( header ) {
		locked = false;
		paint();
	}
};

export const initHeader = () => {
	docReady(() => {

		header = document.getElementById('site-header');

		if (!header) {
			return;
		}

		scroll();
		window.addEventListener('scroll', scroll);

	});
};

export default { initHeader, unlockHeader, lockHeader };
