var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "button",
    {
      staticClass:
        "text-white rounded-full border border-white w-7 h-7 p-2 flex items-center justify-center focus-mouse:outline-none",
      on: {
        mouseover: function($event) {
          return _vm.$emit("mouseover")
        },
        mouseout: function($event) {
          return _vm.$emit("mouseout")
        },
        click: function($event) {
          return _vm.$emit("click")
        }
      }
    },
    [
      _c("span", { staticClass: "visually-hidden" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c(
        "svg",
        {
          staticClass: "svgfull w-2 h-2 fill-current",
          attrs: { xmlns: "http://www.w3.org/2000/svg" }
        },
        [_c("use", { attrs: { "xlink:href": "#svg-" + _vm.icon } })]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }