var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "fixed inset-0 z-200 bg-berry-95 transition-alpha",
      class: {
        invisible: !_vm.visible
      }
    },
    [
      _c(
        "focus-trap",
        {
          staticClass: "absolute inset-0 flex items-center justify-center p-6",
          attrs: { id: "vue-overlay", disabled: !_vm.visible }
        },
        [
          _c("div", {
            staticClass: "absolute inset-0 z-0 cursor-pointer no-mouse-focus",
            on: { click: _vm.dismiss }
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "relative z-0 flex items-center justify-center w-auto h-auto max-h-full overflow-auto scrolling-touch no-overscroll"
            },
            [
              _vm.loading
                ? _c(
                    "div",
                    {
                      staticClass: "relative p-2 m-auto loader z-1 h-100",
                      attrs: { title: "0" }
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass:
                            "text-white uppercase font-sans-thin text-30"
                        },
                        [_vm._v("Loading...")]
                      ),
                      _vm._v(" "),
                      _c(
                        "svg",
                        {
                          staticClass: "mx-auto mt-2",
                          attrs: {
                            version: "1.1",
                            id: "loader-1",
                            xmlns: "http://www.w3.org/2000/svg",
                            "xmlns:xlink": "http://www.w3.org/1999/xlink",
                            x: "0px",
                            y: "0px",
                            width: "40px",
                            height: "40px",
                            viewBox: "0 0 40 40",
                            "enable-background": "new 0 0 40 40",
                            "xml:space": "preserve"
                          }
                        },
                        [
                          _c("path", {
                            attrs: {
                              opacity: "0.2",
                              fill: "#000",
                              d:
                                "M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946\n  s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634\n  c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "path",
                            {
                              attrs: {
                                fill: "#000",
                                d:
                                  "M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0\n  C22.32,8.481,24.301,9.057,26.013,10.047z"
                              }
                            },
                            [
                              _c("animateTransform", {
                                attrs: {
                                  attributeType: "xml",
                                  attributeName: "transform",
                                  type: "rotate",
                                  from: "0 20 20",
                                  to: "360 20 20",
                                  dur: "0.5s",
                                  repeatCount: "indefinite"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "relative m-auto" },
                [
                  _c("div", {
                    ref: "content",
                    staticClass: "relative px-4 py-12 m-auto bg-white z-1",
                    class: {
                      hidden: _vm.loading
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "icon-button",
                    {
                      staticClass:
                        "top-0 right-0 mt-4 mr-4 border no-mouse-focus z-1 hover:text-berry-dark hover:border-berry-dark",
                      class: {
                        "absolute text-berry border-berry": !_vm.loading,
                        "fixed text-white border-white": _vm.loading
                      },
                      attrs: { icon: "close" },
                      on: { click: _vm.dismiss }
                    },
                    [
                      _vm._v(
                        "\n                      Dismiss Overlay\n                  "
                      )
                    ]
                  )
                ],
                1
              )
            ]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }