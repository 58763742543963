import { docReady } from "@/libs/helpers";
import { media } from "@/parts/media";

window.media = media;

docReady(() => {
    let timeoutId;
    const videoWrapper = document.querySelector('[data-video-cover="wrapper"]');
    const videoContainer = document.querySelector('[data-video-cover="container"]');

    if (!videoWrapper) {
        return;
    }

    let aspectRatio = videoContainer.offsetHeight / videoContainer.offsetWidth;

    function setVideoWidth() {
        if (media.matches('tablet')) {
            videoWrapper.removeAttribute('style');
        } else {
            let windowAspect = window.innerHeight / window.innerWidth;

            if (windowAspect > aspectRatio) {
                videoWrapper.style.width = `${(windowAspect / aspectRatio) * 100}%`;
            } else {
                videoWrapper.style.width = `100%`;
            }
        }
    }

    window.addEventListener('resize', () => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(setVideoWidth, 100);
    });

    setVideoWidth();
});
