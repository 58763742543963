<template>
	<div>
		<hr class="border-none h-1 mb-0 -mt-px relative z-1 -mb-1 transition-background" :class="{ 'bg-transparent': !open, 'bg-berry': open, }"/>
		<div class="handorgel px-4 transition-background h-full max-h-filter overflow-y-auto border-t border-light-grey " :class="{ 'bg-pink-white': open }" ref="container">
			<h3 class="handorgel__header text-14 md:text-16 tracking-body w-full items-center flex justify-between">
				<button class="handorgel__header__button py-5 text-left hover:text-berry focus-mouse:outline-none focus-key:border">
					{{ heading }}
					<svg xmlns="http://www.w3.org/2000/svg" class="svgfull absolute right-0 mt-6 w-3 h-3 text-orange fill-current top-0" viewBox="0 0 73.9 44">
					    <path d="M73.9 6.9c0 1.8-.7 3.6-2 5l-30 30c-2.7 2.7-7.2 2.7-9.9 0l-30-30C-.7 9.2-.7 4.7 2 2s7.2-2.7 9.9 0l25 25 25-25c2.7-2.7 7.2-2.7 9.9 0 1.4 1.3 2.1 3.1 2.1 4.9z"/>
					</svg>
				</button>
			</h3>
			<div class="handorgel__content w-full text-indigo">
				<div class="handorgel__content__inner pt-2 pb-6 leading-normal tracking-tight text-16 pr-6">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
/*

	Todo: make this generic, it's far too stylized,
	we should try and use some scoped slots to better allow
	the consumer to style this element.

*/
import handorgel from 'handorgel';

export default {
	props:['heading'],
	mounted() {
		this.$accordion = new handorgel( this.$refs.container, {
			multiSelectable: false,
			headerOpenedClass: 'handorgel__header--opened',
		});

		this.$accordion.on('fold:open', fold => {
			this.open = true;
		});

		this.$accordion.on('fold:close', fold => {
			this.open = false;
		});
	},
	data() {
		return {
			open: false,
		}
	}
}
</script>

<style lang="scss">

$handorgel__content--open-transition-height-time: .1s;
$handorgel__content--open-transition-opacity-time: .2s;
$handorgel__content-transition-height-time: .05s;
$handorgel__content-transition-opacity-time: .05s;

.handorgel {
	& {
		display: block;
		width: 100%;
		//   border: $handorgel-border-width solid $handorgel-border-color;
		//   border-top: none;
	}

	&__header {
		display: block;
		margin: 0;
	}

	&__header--open &__header__button {
		//   background-color: $handorgel__header--open__button-background-color;
	}

	&__header--notransition &__header__button {
		//   transition: none;
	}

	&__header--focus &__header__button {
		//   background-color: $handorgel__header--focus__button-background-color;
		//   outline: none;
	}

	&__header__button {
		//   display: block;
		//   width: 100%;
		//   padding: $handorgel__header__button-padding;
		//   border: none;
		//   border-top: $handorgel-border-width solid $handorgel-border-color;
		//   margin: 0;
		//   background-color: $handorgel__header__button-background-color;
		//   border-radius: 0;
		//   color: inherit;
		//   cursor: pointer;
		//   font-size: inherit;
		//   text-align: left;
		//   transition: $handorgel__header__button-transition;
		//   user-select: none;
		position: relative;
		width: 100%;

		.svgfull {
			transform-origin: center center;
		}

		&[aria-expanded="true"] {
			.svgfull {
				transform: rotate(180deg);
			}
		}

		&::-moz-focus-inner {
			border: 0;
		}
	}

	&__content {
		display: none;
		overflow: hidden;
		height: 0;
		//   border-top: $handorgel-border-width solid $handorgel-border-color;
		//   background-color: $handorgel__content-background;
		transition: height .3s ease;

		&--open {
			display: block;
			// transition: $handorgel__content--open-transition;
		}

		&--opened {
			overflow: visible;
		}

		&--notransition {
			// transition: none;
		}
	}

	&__content__inner {
		//   padding: $handorgel__content__inner-padding;
		opacity: 0;
		transition: opacity .3s ease .3s;

	}

	&__content--opened &__content__inner {
		opacity: 1;
		transition: opacity .3s ease;
	}
}
</style>
