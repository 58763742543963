<template>
	<div id="news-results-list" v-if="runOnce || shadowTopic || shadowAuthor">
		<div  v-if="query || shadowTopic || shadowAuthor" class="flex pb-2 mt-1 border-b-2 md:pt-15 lg:pt-12 pt-3px border-berry">
			<div class="font-sans-bold" :class="{ 'lg:text-60 text-48': !query || !shadowTopic || !shadowAuthor, 'lg:text-48 text-36': (query && shadowTopic) || (query && shadowAuthor), }">
				<span class="inline-block" v-if="query">
					<span class="text-grey">Searched for</span>
					<em>'{{ query }}'</em>

					<button @click="query = ''" :class="buttonClass">
						<span class="visually-hidden">Clear Search Query</span>
						<svg focusable="false" xmlns="http://www.w3.org/2000/svg" class="block w-2 h-2 pointer-events-none fill-current svgfull">
							<use xlink:href="#svg-close"></use>
						</svg>
					</button>

					<span class="text-grey" v-if="shadowTopic">in</span>
					<span class="text-grey" v-if="shadowAuthor">by</span>
				</span>
				<span class="inline-block" v-if="shadowTopic">
					<span>{{ shadowTopic.title }}</span>
					<button @click="topic = false;" :class="buttonClass">
						<span class="visually-hidden">Clear Topic Filter</span>

						<svg focusable="false" xmlns="http://www.w3.org/2000/svg" class="block w-2 h-2 pointer-events-none fill-current svgfull">
							<use xlink:href="#svg-close"></use>
						</svg>
					</button>
				</span>

                <span class="inline-block" v-if="shadowAuthor">
					<span>{{ shadowAuthor.title }}</span>
					<button @click="author = false;" :class="buttonClass">
						<span class="visually-hidden">Clear Author Filter</span>

						<svg focusable="false" xmlns="http://www.w3.org/2000/svg" class="block w-2 h-2 pointer-events-none fill-current svgfull">
							<use xlink:href="#svg-close"></use>
						</svg>
					</button>
				</span>
			</div>
		</div>

		<div class="relative">
			<loading-results :loading="loading">
				<template v-if="loading">
					Loading News...
				</template>
				<template v-else>
					Finished Loading. {{ news.length }} News Found.
				</template>
			</loading-results>

            <div class="flex flex-wrap pt-6 -mx-4">
                <news-preview v-for="entry in news" :key="entry.id" :entry="entry"/>
                <div v-if="news.length === 0">
                    <div class="p-4">No news found. Try widening your search</div>
                </div>
			</div>
		</div>
	</div>
</template>

<script>
import NewsPreview from "@/components/shared/NewsPreview.vue";
import LoadingResults from "@/components/generic/LoadingResults.vue";

export default {
	components: {
		NewsPreview,
		LoadingResults,
	},
	store: ['news', 'topics', 'topic', 'authors', 'author', 'topicId', 'authorId', 'loading', 'query', 'runOnce'],

	// Use a shadow category, to avoid changing 'too soon'

	watch: {
		loading: {
			immediate: true,
			handler() {
				if (!this.loading) {
					this.shadowTopic = this.topic;
					this.shadowAuthor = this.author;
				}
			}
		},
	},

	data() {
		return {
			shadowTopic: this.topic,
			shadowAuthor: this.author,
			buttonClass: 'text-white rounded-full ml-0 w-5 h-5 align-top p-6px inline-flex items-center justify-center bg-pink-faded',
		}
	}
}
</script>

<style>
</style>
