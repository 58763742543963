/* eslint-disable no-param-reassign */
import { qsa, docReady } from "@/libs/helpers";

docReady(() => {

	qsa( '[data-link-cover]', card => {
		let down;
		let up;
		const link = card.querySelector('[data-link-target]');

		const onDown = () => {
			console.log("On Down", link.href );
			down = +new Date();
		};

		const onUp = () => {
			up = +new Date();
			if ((up - down) < 200) {
				console.log("On UP", link.href );
				link.click();
			}
		};

		card.style.cursor = 'pointer';
		card.onmousedown = onDown;
		card.ontouchstart = onDown;
		card.onmouseup = onUp;
		card.ontouchend = onUp;
	});

});
