var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { "aria-controls": "project-results-list" } },
    [
      _c("div", { staticClass: "text-14 md:text-16 relative -mb-px" }, [
        _c(
          "form",
          {
            on: {
              submit: function($event) {
                $event.preventDefault()
                return _vm.getData($event)
              }
            }
          },
          [
            _c("fieldset", [
              _c("legend", { staticClass: "visually-hidden" }, [
                _vm._v("Search For Projects")
              ]),
              _vm._v(" "),
              _c(
                "label",
                {
                  staticClass: "visually-hidden",
                  attrs: { for: _vm.namespace + "__searchId" }
                },
                [_vm._v("Search by name")]
              ),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.query,
                    expression: "query"
                  }
                ],
                staticClass:
                  "m-0 px-4 py-5 w-full border-t border-b focus-mouse:outline-none focus-mouse:border-berry border-light-grey",
                class: { italic: !_vm.query },
                attrs: {
                  placeholder: "Search by name",
                  id: _vm.namespace + "__searchId",
                  type: "text"
                },
                domProps: { value: _vm.query },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.query = $event.target.value
                    },
                    _vm.updateQuery
                  ]
                }
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass:
                    "absolute text-left top-0 right-0 mt-0 z-4 h-full w-7 mr-3 flex items-center justify-center text-charcoal focus:text-berry hover:text-berry focus-mouse:outline-none",
                  attrs: { "aria-label": "Submit Search" },
                  on: { click: _vm.getData }
                },
                [
                  _c(
                    "svg",
                    {
                      staticClass:
                        "pointer-events-none w-7 h-7 block fill-current",
                      attrs: {
                        focusable: "false",
                        xmlns: "http://www.w3.org/2000/svg"
                      }
                    },
                    [_c("use", { attrs: { "xlink:href": "#svg-search" } })]
                  )
                ]
              )
            ])
          ]
        )
      ]),
      _vm._v(" "),
      _c(
        "handorgel-accordion",
        {
          staticClass: "border-b border-light-grey",
          attrs: { heading: "Filter by Expertise" }
        },
        [
          _c(
            "ul",
            { staticClass: "list-reset", attrs: { tabindex: "-1" } },
            _vm._l(_vm.expertise, function(category) {
              return _c("li", { key: category.id }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "hover:text-berry text-left focus-mouse:outline-none text-14 md:text-16 hover:underline bg-transparent border-none px-2 py-1",
                    class: {
                      "text-berry": category.id === _vm.categoryId,
                      "text-black": category.id !== _vm.categoryId
                    },
                    attrs: {
                      "aria-pressed":
                        category.id === _vm.categoryId ? "true" : "false",
                      "aria-label":
                        category.id === _vm.categoryId
                          ? "Remove Projects filter: " + category.title
                          : "Apply Projects filter: " + category.title,
                      type: "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.filterByCategory(category)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(category.title) +
                        "\n                "
                    )
                  ]
                )
              ])
            }),
            0
          )
        ]
      ),
      _vm._v(" "),
      _c(
        "handorgel-accordion",
        {
          staticClass: "border-b border-light-grey",
          attrs: { heading: "Filter by Services" }
        },
        [
          _c(
            "ul",
            { staticClass: "list-reset", attrs: { tabindex: "-1" } },
            _vm._l(_vm.services, function(category) {
              return _c("li", { key: category.id }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "hover:text-berry text-left focus-mouse:outline-none text-14 md:text-16 hover:underline bg-transparent border-none px-2 py-1",
                    class: {
                      "text-berry": category.id === _vm.categoryId,
                      "text-black": category.id !== _vm.categoryId
                    },
                    attrs: {
                      "aria-pressed":
                        category.id === _vm.categoryId ? "true" : "false",
                      "aria-label":
                        category.id === _vm.categoryId
                          ? "Remove Projects filter: " + category.title
                          : "Apply Projects filter: " + category.title,
                      type: "button"
                    },
                    on: {
                      click: function($event) {
                        return _vm.filterByCategory(category)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n                    " +
                        _vm._s(category.title) +
                        "\n                "
                    )
                  ]
                )
              ])
            }),
            0
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }