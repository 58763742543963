import { docReady } from "@/libs/helpers";

const serialize = function(obj, prefix) {
	const str = [];
	Object.entries(obj).forEach(([p, v]) => {
		const k = prefix ? `${prefix}[${p}]` : p;
		const thisString = (v !== null && typeof v === "object") ? serialize(v, k) : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`;
		str.push(thisString);
	});
	return str.join("&");
};

window.MCCALLBACKS = {};

docReady(() => {

	const form = document.getElementById('mc-embedded-subscribe-form');
	const input = document.getElementById('mce-EMAIL');

	const response = document.getElementById('mce-response');

	if (!form) {
		return;
	}

	const reportResponse = (msg) => {
		response.style.display = 'block';
		response.innerHTML = msg;
		form.style.display = 'none';
	};

	const hideReponse = () => {
		response.style.display = 'hidden';
		response.innerHTML = '';
		form.style.display = 'block';
	};

	const handleResponse = function(json, form) {

		let resultMessage = json.hasOwnProperty('result') ? json.result : 'unknown';
		let msg = json.hasOwnProperty('msg') ? json.msg : 'Thanks for subscribing!';

		if (resultMessage == 'success') {
			reportResponse( msg );

		} else if (resultMessage == 'error') {
			reportResponse(json.msg);
		} else {
			console.warn("Unknown response", json);
			reportResponse("Sorry, there was an error");
		}
	};

	form.addEventListener('submit', e => {

		if (!('FormData' in window)) {
			// Just ignore this crappy old browser...
			return;
		}

		e.preventDefault();

		if (!input.checkValidity()) {
			input.reportValidity(true);
			return;
		}

		const data = new FormData(form);
		const callbackString = `cb_${  new Date().getTime()}`;

		const plaindata = {};
		plaindata.EMAIL = data.get('EMAIL');
		plaindata.c = `MCCALLBACKS.${callbackString}`;

		const url = `${form.getAttribute('data-json')}&${serialize(plaindata)}`;

		window.MCCALLBACKS[callbackString] = function (json) {
			handleResponse(json, form);
			// erase self
			delete window.MCCALLBACKS[callbackString];
		};

		const script = document.createElement('script');
		script.type = 'text/javascript';
		script.src = url;
		document.getElementsByTagName('head')[0].appendChild(script);

	});


});
