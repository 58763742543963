<template>
    <div aria-controls="project-results-list">
        <div class="text-14 md:text-16 relative -mb-px">
            <form @submit.prevent="getData">
                <fieldset>
                    <legend class="visually-hidden">Search For Projects</legend>

                    <label class="visually-hidden" :for="`${namespace}__searchId`">Search by name</label>
                    <input @input="updateQuery" placeholder="Search by name" :class="{ 'italic': !query, }" class="m-0 px-4 py-5 w-full border-t border-b focus-mouse:outline-none focus-mouse:border-berry border-light-grey" :id="`${namespace}__searchId`" type="text" v-model="query"/>
                    <button class="absolute text-left top-0 right-0 mt-0 z-4 h-full w-7 mr-3 flex items-center justify-center text-charcoal focus:text-berry hover:text-berry focus-mouse:outline-none" aria-label="Submit Search" @click="getData">
                        <svg focusable="false" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none w-7 h-7 block fill-current">
                            <use xlink:href="#svg-search"></use>
                        </svg>
                    </button>
                </fieldset>
            </form>
        </div>
        <handorgel-accordion class="border-b border-light-grey" heading="Filter by Expertise">
            <ul tabindex="-1" class="list-reset">
                <li :key="category.id" v-for="category in expertise">
                    <button :aria-pressed="category.id === categoryId ? 'true' : 'false'"
                            :class="{ 'text-berry': category.id === categoryId, 'text-black': category.id !== categoryId, }"
                            class="hover:text-berry text-left focus-mouse:outline-none text-14 md:text-16 hover:underline bg-transparent border-none px-2 py-1"
                            @click="filterByCategory( category )" :aria-label="category.id === categoryId ? `Remove Projects filter: ${category.title}` : `Apply Projects filter: ${category.title}`"
                            type="button">
                        {{ category.title }}
                    </button>
                </li>
            </ul>
        </handorgel-accordion>

        <handorgel-accordion class="border-b border-light-grey" heading="Filter by Services">
            <ul tabindex="-1" class="list-reset">
                <li :key="category.id" v-for="category in services">
                    <button :aria-pressed="category.id === categoryId ? 'true' : 'false'"
                            :class="{ 'text-berry': category.id === categoryId, 'text-black': category.id !== categoryId, }"
                            class="hover:text-berry text-left focus-mouse:outline-none text-14 md:text-16 hover:underline bg-transparent border-none px-2 py-1"
                            @click="filterByCategory( category )"
                            :aria-label="category.id === categoryId ? `Remove Projects filter: ${category.title}` : `Apply Projects filter: ${category.title}`"
                            type="button">
                        {{ category.title }}
                    </button>
                </li>
            </ul>
        </handorgel-accordion>
    </div>
</template>

<script>
    import axios from "axios";
    import HandorgelAccordion from "@/components/generic/HandorgelAccordion.vue";

    export default {
        store: ['projects', 'expertise', 'services', 'category', 'categoryId', 'loading', 'query', 'atoz'],
        components: {
            HandorgelAccordion,
        },
        data() {
            return {
                timer: false,
                runOnce: false,
            }
        },
        computed: {
            intro() {
                return window.VM.intro;
            },
            namespace() {
                return this.$root.namespace;
            },
        },
        watch: {
            category() {
                this.getData();
                if (this.category) {
                    history.pushState({}, "Projects A-Z", this.category.url);
                } else {
                    history.pushState({}, "Projects A-Z", window.VM.allProjectsUrl);
                }
            }
        },
        created() {
            // Just revert to normal behaviour if history was changed.
            window.onpopstate = () => {
                window.location.href = window.location.href;
            }
        },
        methods: {
            filterByCategory(category = false) {
                if (!category || (this.categoryId == category.id && category.id)) {
                    this.category = false;
                } else {
                    this.category = category;
                }
            },
            updateQuery() {
                clearTimeout(this.timer);
                this.loading = true;
                this.timer = setTimeout(() => {
                    this.getData();
                }, 500);
            },
            hideFeatured() {
                this.runOnce = true;
                const fp = document.getElementById('featured-projects');
                if (fp) {
                    // Get rid of featured projects
                    fp.remove();
                    // move to the top
                    window.scrollTo(0, 0);
                    // change state to being an a-z listing.
                    this.atoz = true;

                    if ('history' in window) {
                        history.pushState({}, "Projects A-Z", window.VM.allProjectsUrl);
                    }
                }
            },
            getData() {
                if (!this.runOnce) {
                    this.hideFeatured();
                }

                const transformString = `width: 1280, height: 720, position: "topLeft", quality: 50, format: "jpg"`;
                let categoryFilter = '';

                if (this.categoryId) {
                    categoryFilter = `relatedTo: ${this.categoryId},`;
                }

                const query = `query ProjectsQuery {
                    projects: entries(
                        section: "projects",
                        limit: 40,
                        ${categoryFilter} search: "${this.query.trim()}"
                    ) {
                        title
      		            url
      		            id
      		            ... on projects_projects_Entry {
      			            gridImage(limit:1) {
      				            title
      				            id
      				            width @transform (${transformString})
      				            height @transform (${transformString})
      				            url @transform (${transformString})
      			            }
      		            }
      	            }
                }`;

                const headers = {
                    "Authorization": `Bearer ${APP.config.gqlToken}`,
                    "X-Requested-With": "XMLHttpRequest",
                    "Content-type": "application/json",
                };

                this.loading = true;

                axios.post(
                    '/api',
                    { query },
                    { headers }
                ).then(({data: response}) => {
                    // Yeah, I know, I know,
                    const { data, errors } = response;

                    this.loading = false;

                    if (errors) {
                        errors.forEach(error => {
                            console.log(error);
                        });
                        return;
                    }

                    if (!data) {
                        console.log("Unknown response");
                        return;
                    }

                    const { projects } = data;

                    if (projects) {
                        this.projects = projects;
                        return;
                    }

                    console.log("Unknown response");
                    return;

                }).catch(e => {
                    console.error(e);
                    this.loading = false;
                });
            }
        }
    }
</script>

<style>
</style>
