<template>
	<div>
		<div class="absolute inset-0 bg-white-80 z-10 flex items-start py-28 px-6 md:px-14 justify-start" v-if="loading">
			<div aria-live="assertive" role="alert" class="font-sans-bold mt-1 text-20">
				<slot />
			</div>
		</div>
		<div v-else class="visually-hidden" aria-live="assertive" role="alert">
			<!-- Show the alert always, but hidden for screen readers. Should say something like "finished loading"  -->
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	props: ['loading'],
}
</script>

<style>

</style>
