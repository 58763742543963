<template>
	<div id="project-results-list" class="md:ml-c1">
		<h2 v-if="!atoz" class="text-22 tracking-tight font-sans-bold">Projects A-Z</h2>

		<div v-if="query || shadowCategory" class="flex mt-1 md:pt-15 lg:pt-12 pt-3px pb-2 border-b-2 border-berry">
			<div class="font-sans-bold" :class="{ 'lg:text-60 text-48': !query || !shadowCategory, 'lg:text-48 text-36': query && shadowCategory, }">
				<span class="inline-block" v-if="query">
					<span class="text-grey">Searched for</span>
					<em>'{{ query }}'</em>

					<button @click="query = ''" :class="buttonClass">
						<span class="visually-hidden">Clear Search Query</span>
						<svg focusable="false" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none fill-current svgfull block w-2 h-2">
							<use xlink:href="#svg-close"></use>
						</svg>
					</button>

					<span class="text-grey" v-if="shadowCategory">in</span>
				</span>
				<span class="inline-block" v-if="shadowCategory">
					<span>{{ shadowCategory.title }}</span>
					<button @click="category = false;" :class="buttonClass">
						<span class="visually-hidden">Clear Category Filter</span>

						<svg focusable="false" xmlns="http://www.w3.org/2000/svg" class="pointer-events-none fill-current svgfull block w-2 h-2">
							<use xlink:href="#svg-close"></use>
						</svg>
					</button>
				</span>

				<!-- <span v-if="!shadowCategory && !query">
					All Projects A-Z
				</span> -->

			</div>
		</div>

		<div class="relative">

			<loading-results :loading="loading">
				<template v-if="loading">
					Loading Projects...
				</template>
				<template v-else>
					Finished Loading. {{ projects.length }} Projects Found.
				</template>
			</loading-results>

			<ul class="list-reset flex flex-wrap -mx-2 lg:-mx-4 pt-2">
				<project-preview v-for="project in projects" :key="project.id" :entry="project"/>
				<li v-if="projects.length === 0">
					<div class="p-4">No projects found. Try widening your search</div>
				</li>
			</ul>
		</div>
	</div>
</template>

<script>
import ProjectPreview from "@/components/shared/ProjectPreview.vue";
import LoadingResults from "@/components/generic/LoadingResults.vue";

export default {
	components: {
		ProjectPreview,
		LoadingResults,
	},
	store: ['projects', 'category', 'loading', 'query', 'atoz'],

	// Use a shadow category, to avoid changing 'too soon'

	watch: {
		loading: {
			immediate: true,
			handler() {
				if (!this.loading) {
					this.shadowCategory = this.category;
				}
			}
		},
	},

	data() {
		return {
			shadowCategory: this.category,
			buttonClass: 'text-white rounded-full ml-0 w-5 h-5 align-top p-6px inline-flex items-center justify-center bg-pink-faded',
		}
	}
}
</script>

<style>
</style>
