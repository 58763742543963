<template>
	<li class="flex flex-col px-2 lg:px-4 w-c6 md:w-c4">
		<a :href="entry.url" class="flex flex-col h-full no-underline cursor-pointer hover:underline hover:text-berry group">
			<div class="h-full pt-3 pb-2 border-b border-light-grey md:pt-4 sm:pb-4">
				<div class="ar-3x2 bg-pink-white">
					<img v-if="image" :src="image.url"
					class="absolute block object-cover w-full h-full m-0 transition-opacity group-hover:opacity-75 backface-hidden lazyload" />
				</div>
				<h2 class="mt-2 mb-0 text-12 sm:text-14 font-sans-bold text-offblack sm:mt-5">{{ title }}</h2>
			</div>
		</a>
	</li>
</template>

<script>
export default {
	props: ['entry'],
	computed: {
		image() {
			if ( this.entry.gridImage && this.entry.gridImage.length ) {
				return this.entry.gridImage[0];
			}

			return false;
		},
		title() {
			return this.entry.title;
		}
	}
}
</script>

<style>

</style>


