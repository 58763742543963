import Vue from "vue";
import axios from "axios";
import AppModal from "@/components/AppModal.vue";
import { qsa, docReady, onClick } from "@/libs/helpers";

docReady(() => {
    const el = document.getElementById('vue-modal');
    if ( !el ) {
        return;
    }

    const data = {
        content: false,
        loading: false,
        visible: false,
    };

    // this will trigger a dismissal of the ajax.
    const initialState = {
        visible: false,
        loading: false,
        content: false,
        // Tell the pop state event to listen...
        modalHistory: true,
    };

    const headTitleEl = document.querySelector('title');
    const headTitle = headTitleEl ? headTitleEl.textContent : '';
    const dismissUrl = window.location.href;

    if ( !history.state ) {
        history.replaceState( initialState, headTitle , window.location.href );
    }

    const v = new Vue({
        el,
        data,
        render: h => h( AppModal ),
        computed: {
            initialState() {
                // Make it read only, and not reactive.
                return initialState;
            },
            dismissUrl() {
                // Make it read only, and not reactive.
                return dismissUrl;
            },
            dismissTitle() {
                // Make it read only, and not reactive.
                return headTitle;
            }
        },
        watch: {
            visible( newValue ) {
                if ( !newValue ) {
                    // if visible becomes 'false' then the modal has been dismissed.
                    // so let's empty the target HTML
                    setTimeout(function() {
                        this.content = false;
                    }, 3000, this);
                }
            },
            // We don't need to do anything, since we _copied_ the innerHTML,
            // So this comes with a potential problem with event listeners
            // and duplicating IDs. So if we end up modal-appending complicated
            // things, consider a better more involved Vue component for that.
            // But to just throw an embed or a picture or something into a modal,
            // this'll do fine:

            // target( newValue, oldValue ) {
            // 	// Nothing
            // }
        }
    });

    onClick('[data-trigger-modal]', function(e) {

        const targetId = this.getAttribute('data-trigger-modal');
        const url = this.getAttribute('href');
        let target;

        if ( url && e.button === 2 || e.metaKey ) {
            return;
        }

        // 'this' is the button that was clicked,
        e.preventDefault();

        if ( url ) {
            // If it's a URL, let's add this to the history state.
            // We need to bear in mind that other parts of the app
            // may one day use the history, and this could clash,
            // but being realistic right now we can just treat this simply.

            let title = this.getAttribute('data-title') || ';';

            // Set data to loading,
            data.loading = true;
            // But clear content in case,
            data.content = '';
            // and make modal pop up instantly so user is not waiting...
            data.visible = true;

            // optionally make the ajax on a different URL
            // but use the default URL for the pushState
            let altUrl = this.getAttribute('data-modal-url');

            axios.get( altUrl || url, {
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                }
            } ).then( response => {
                // populate the requested content.
                // console.log( response );
                data.content = response.data;
                data.loading = false;
                history.pushState( {
                    ...data,
                    modalHistory: true
                }, title, url  );
            });

        } else {
            target = document.querySelector( targetId );
            if ( !target ) { return; }
            // Data above will now be a reactive object, so we can set the target and set the visibility,
            // thus launching the modal:
            data.content = target.innerHTML;
            data.loading = false;
            data.visible = true;
        }

        window.onpopstate = ( { state } ) => {
            console.log( state );
            const { modalHistory, visible, content } = state;

            if ( !modalHistory ) {
                // It's someone else's, so let's leave it be
                return;
            }

            data.content = content;
            data.visible = visible;

            // We should be able to load as is, no need for an ajax.
            data.loading = false;
        };
    });
});
