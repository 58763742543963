<template>
	<button
	@mouseover="$emit('mouseover')"
	@mouseout="$emit('mouseout')"
	@click="$emit('click')"
	class="text-white rounded-full border border-white w-7 h-7 p-2 flex items-center justify-center focus-mouse:outline-none">
		<span class="visually-hidden">
			<slot />
		</span>
		<svg xmlns="http://www.w3.org/2000/svg" class="svgfull w-2 h-2 fill-current">
			<use :xlink:href="`#svg-${icon}`"></use>
		</svg>
	</button>
</template>

<script>
export default {
	props:['icon']
}
</script>

<style>

</style>
