<template>
	<div class="fixed inset-0 z-200 bg-black-80 transition-alpha"
		:class="{
			'invisible' : !open,
		}">


		<focus-trap class="absolute inset-0 flex items-center justify-center" :disabled="!open">

		<!-- Dismiss Zone -->
		<div class="absolute inset-0 z-0 cursor-pointer" @mouseup="close"></div>

		<div class="relative flex flex-col w-full max-w-5xl px-4 antialiased text-white z-1 sm:flex-row sm:px-0">
			<div class="flex items-center sm:flex-no-shrink flex-no-grow sm:w-48">
				<!-- Keeps things even -->
                <icon-button icon="close" @click="close" class="my-2 ml-auto sm:hidden">
                    Dismiss Overlay
                </icon-button>
			</div>
			<div class="flex items-center flex-grow">
                <icon-button icon="arrow-left" @click="prev" class="flex-shrink-0 mr-4 sm:hidden">
                    Previous Image
                </icon-button>
				<div class="ar-8x5">
					<div class="absolute inset-0">
                        <div ref="carousel" class="w-full h-full">
                            <div v-for="(img, i) in cells" class="absolute w-full h-full" :key="`cell-${i}`">
                                <img class="object-contain" :src="img.src" />
                            </div>
                        </div>
					</div>
				</div>
                <icon-button icon="arrow-right" @click="next" class="flex-shrink-0 ml-4 sm:hidden">
                    Next Image
                </icon-button>
			</div>
			<div class="hidden px-4 sm:block sm:flex-no-shrink flex-no-grow sm:w-48">
				<icon-button icon="close" @click="close" class="mb-12">
					Dismiss Overlay
				</icon-button>

				<icon-button icon="arrow-right" @click="next" class="my-1">
					Next Image
				</icon-button>

				<icon-button icon="arrow-left" @click="prev" class="my-1">
					Previous Image
				</icon-button>

				<div v-if="caption.length" class="py-6 mt-6 leading-normal border-t border-b border-white-20 md:text-18">
					{{ caption }}
				</div>
			</div>

            <div v-if="caption.length" class="py-2 mt-2 leading-normal text-center border-t border-b sm:hidden border-white-20">
                {{ caption }}
            </div>
		</div>


		</focus-trap>

	</div>
</template>

<script>
import FocusTrap from "@/components/generic/FocusTrap.vue";
import IconButton from "@/components/generic/IconButton.vue";
import Flickity from 'flickity';


export default {
	store:['open', 'cells', 'trigger'],
	components: {
		FocusTrap,
		IconButton,
	},
	data() {
		return {
			index: 0,
		}
	},
	created() {


		const escapeHandler = e => {
			if (e.key === "Escape" && this.open) {
				this.close();
			}
		};

		document.addEventListener("keydown", escapeHandler)
		this.$once("hook:destroyed", () => {
			document.removeEventListener("keydown", escapeHandler)
		});


		this.$flkty = false;
	},
	mounted() {
		this.getCells();

	},
	watch: {
		cells: {
			immediate: true,
			handler() {
				this.getCells();
			}
		},
		open: {
			handler() {

				if (!this.open) {
					this.trigger = false;
					return;
				}

				if ( this.trigger ) {

					this.cells.forEach( (c,i) => {
						if ( c.src === this.trigger ) {
							this.updateCarousel( i );
						}
					});

				}

				this.trigger = false;
			}
		}
	},
	computed: {
		caption() {
			return this.cells[ this.index ].caption || "";
		}
	},
	methods: {

		updateCarousel( i ) {

			if ( typeof i === "number" ) {
				this.index = i;
			}

			if ( this.$flkty ) {
				this.$flkty.select( this.index, true, true );
			}
		},
		getCells() {

			if ( this.$flkty ) {
				this.$flkty.destroy();
			}

			this.$nextTick( () => {

				this.$flkty = new Flickity( this.$refs.carousel, {
					cellSelector: 'img',
					fade: true,
					prevNextButtons: false,
					pageDots: false,
					adaptiveHeight: false,
					wrapAround: true,
					setGallerySize: false,
				});

				this.$flkty.on('select', e => {
					this.index = this.$flkty.selectedIndex;
				});

			});
		},
		close() {
			this.open = false;
		},
		next() {
			console.log("Next");
			if ( this.$flkty ) {
				this.$flkty.next();
			}
		},
		prev() {
			if ( this.$flkty ) {
				this.$flkty.previous();
			}

		}
	}
}
</script>

<style>
.flickity-enabled.is-fade .flickity-slider > * {
  pointer-events: none;
  z-index: 0;
}

.flickity-enabled.is-fade .flickity-slider > .is-selected {
  pointer-events: auto;
  z-index: 1;
}

.object-contain {
	width: 100%;
	height: auto;

	max-width: 100%;
	max-height: 100%;
}

@supports ( object-fit: contain ) {
	.object-contain {
		width: 100%;
		height: 100%;
		object-fit: contain;
	}
}
</style>
