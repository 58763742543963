/* eslint-disable */
const rem = (i) => `${i * 4 / 16}rem`;

const colors = {
    'transparent': 'transparent',
    'current': 'currentColor',
    'inherit': 'inherit',
    'white': '#fff',
    'white-20': 'rgba(255,255,255,0.2)',
    'white-40': 'rgba(255,255,255,0.4)',
    'white-80': 'rgba(255,255,255,0.8)',
    'black-80': 'rgba(0, 0, 0, 0.8)',
    'black-20': 'rgba(0, 0, 0, 0.2)',
    'black-30': 'rgba(0, 0, 0, 0.3)',
    'black': 'rgb(0, 10, 18)',
    'offblack': 'rgb(44, 41, 41)',
    'charcoal': 'rgb(64, 64, 62)',
    'light-charcoal': 'rgb(94, 95, 98)',
    'grey': 'rgb(155, 155, 155)',
    'grey-header': '#EAEAEA',
    'grey-carousel': '#FAFAFA',
    'light-grey': 'rgb(209, 209, 210)',
    'very-light-grey': 'rgb(240, 240, 240)',
    'very-lighter-grey': 'rgb(251, 251, 251)',
    'berry': 'rgb(154, 29, 75)',
    'berry-95': 'rgba(154, 29, 75,0.95)',
    'berry-tint': '#8B1042',
    'berry-dark': 'rgb(120, 15, 53)',
    'pink': 'rgb(247, 62, 131)',
    'pink-faded': 'rgb(225, 131, 166)',
    'pink-light': 'rgb(255, 183, 210)',
    'pink-white': '#F9EEED',
};

const columns = {
    'c1' : '8.333%', // c1 // '1/12':
    'c2' : '16.66667%', // c2 // '1/6':
    'c3' : '25%', // c3 // '1/4':
    'c4' : '33.33333%', // c4 // '1/3':
    'c5' : '41.667%', // c5 // '5/12':
    'c6' : '50%', // c6 // '1/2':
    'c7' : '58.333%', // c7 // '7/12':
    'c8' : '66.66667%', // c8 // '2/3':
    'c9' : '75%', // c9 // '3/4':
    'c10' : '83.33333%', // c10 // '5/6':
    'c11' : '91.667%', // c11 // '11/12':

    'd1' : '4.166666666666667%', // d1 // '1/24':
    'd2' : '8.333333333333334%', // d2 // '2/24':
    'd3' : '12.5%', // d3 // '3/24':
    'd4' : '16.666666666666668%', // d4 // '4/24':
    'd5' : '20.833333333333336%', // d5 // '5/24':
    'd6' : '25%', // d6 // '6/24':
    'd7' : '29.166666666666668%', // d7 // '7/24':
    'd8' : '33.333333333333336%', // d8 // '8/24':
    'd9' : '37.5%', // d9 // '9/24':
    'd10' : '41.66666666666667%', // d10 // '10/24':
    'd11' : '45.833333333333336%', // d11 // '11/24':
    'd12' : '50%', // d12 // '12/24':
    'd13' : '54.16666666666667%', // d13 // '13/24':
    'd14' : '58.333333333333336%', // d14 // '14/24':
    'd15' : '62.50000000000001%', // d15 // '15/24':
    'd16' : '66.66666666666667%', // d16 // '16/24':
    'd17' : '70.83333333333334%', // d17 // '17/24':
    'd18' : '75%', // d18 // '18/24':
    'd19' : '79.16666666666667%', // d19 // '19/24':
    'd20' : '83.33333333333334%', // d20 // '20/24':
    'd21' : '87.5%', // d21 // '21/24':
    'd22' : '91.66666666666667%', // d22 // '22/24':
    'd23' : '95.83333333333334%', // d23 // '23/24':
};

const sizes = {
    px: '1px',
    '2px': '2px',
    '6px': '6px',
    0: '0',
    1: '0.25rem',
    2: '0.5rem',
    3: '0.75rem',
    4: '1rem',
    5: '1.25rem',
    6: '1.5rem',
    7: '1.75rem',
    8: '2rem',
    9: '2.25rem',
    10: '2.5rem',
    12: '3rem',
    14: '3.5rem',
    15: '3.75rem',
    16: '4rem',
    15: rem(15),
    18: rem(18),
    19: rem(19),
    20: rem(20),
    21: rem(21),
    24: '6rem',
    25: '6.25rem',
    30: '7.5rem',
    32: '8rem',
    34: '8.5rem',
    36: '9rem',
    36: rem(36),
    38: rem(38),
    40: rem(40),
    48: '12rem',
    60: '15rem',
    64: '16rem',
    68: '17rem',
    72: '18rem',
    76: '19rem',
    80: '20rem',
    90: '22.5rem',
    100: '25rem',
    110: '27.5rem',
    120: '30rem',
    125: '31.25rem',
    142: '34.6rem',
    144: '36rem',
    152: '41rem',
    156: '42rem',
    170: '42.5rem',
    182: '45.625rem',
    184: '46rem',
    392: '98rem',
    '1/5': '20%',
    '2/5': '40%',
    '3/5': '60%',
    '4/5': '80%',

    ...columns,

    xs: '28.5rem',
    sm: '32.3125rem',
    md: '40rem',
    lg: '43.125rem',
    xl: '60rem',  // 960
    '2xl': '70rem', // 1120
    '3xl': '80rem', // 1280,
    '4xl': '90rem', // 1440
    '5xl': '100rem', // 1600px
    '6xl': '120rem', // 1920px
    full: '100%',

    // edge case, hero image.
    '42vw':'42vw',
    '70vw': '70vw',
    '80vw': '80vw',
    peek: 'calc(100vh - 40px)',
    'header': '136px',
};


const spacing = {
    px: '1px',
    '3px': '3px',
    '6px': '6px',
    0: '0',
    1: rem(1),
    2: rem(2),
    3: rem(3),
    4: rem(4),
    5: rem(5),
    6: rem(6),
    7: rem(7),
    8: rem(8),
    9: rem(9),
    10: rem(10),
    12: rem(12),
    13: rem(13),
    14: rem(14),
    15: rem(15),
    16: rem(16),
    18: rem(18),
    19: rem(19),
    20: rem(20),
    22: rem(22),
    23: rem(23),
    24: rem(24),
    26: rem(26),
    28: rem(28),
    30: rem(30),
    32: rem(32),
    36: rem(36),
    38: rem(38),
    40: rem(40),
    46: rem(46),
    60: rem(60),
    82: rem(82),
    110: rem(110),

    ...columns,
};

const marginSpacing = {
    '1/2': '50%',
    '1/4': '25%',
    '66vw': '66vw',
};

module.exports = {
    prefix: '',
    important: false,
    separator: ':',
    theme: {
        aspectRatio: {
            '16x9': 16 / 9,
            '3x2': 3 / 2,
            '4x3': 4 / 3,
            '6x5': 6 / 5,
            '7x5': 7 / 5,
            '8x5': 8 / 5,
            '9x7': 9 / 7,
            '13x7': 13 / 7,
            '25x9': 25 / 9,
            '16x7': 16 / 7,
            '25x32': 25 / 32,
            '33x19': 33 / 19,
            '89x64': 89 / 64,
            '178x93': 178 / 93,
            '410x191': 410 / 191,
        },
        backgroundColor: colors,
        backgroundSize: {
            auto: 'auto',
            cover: 'cover',
            contain: 'contain',
        },
        borderColor: global.Object.assign({ default: colors['black'] }, colors),
        borderRadius: {
            none: '0',
            sm: '.125rem',
            default: '.25rem',
            lg: '.5rem',
            full: '9999px',
        },
        borderWidth: {
            default: '1px',
            0: '0',
            2: '2px',
            4: '4px',
            6: '6px',
            8: '8px',
        },
        boxShadow: {
            default: '0 2px 4px 0 rgba(0,0,0,0.10)',
            drop: '0 0 101px 0 rgba(0, 0, 0, 0.35)',
            md: '0 0 8px 0 rgba(0,0,0,0.12)',
            // md: '0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08)',
            lg: '0 15px 30px 0 rgba(0,0,0,0.11), 0 5px 15px 0 rgba(0,0,0,0.08)',
            inner: 'inset 0 2px 4px 0 rgba(0,0,0,0.06)',
            outline: '0 0 0 3px rgba(52,144,220,0.5)',
            none: 'none',
        },
        colors,
        fill: {
            current: 'currentColor',
        },
        fontFamily: {
            sans: [
                'system-ui',
                'BlinkMacSystemFont',
                '-apple-system',
                'Segoe UI',
                'Roboto',
                'Oxygen',
                'Ubuntu',
                'Cantarell',
                'Fira Sans',
                'Droid Sans',
                'Helvetica Neue',
                'sans-serif',
            ],

            // Trial fonts from myfonts.com
            'sans-light': ['"Neue Helvetica Std 45 Light"', 'sans-serif'],
            'sans-thin': ['"Neue Helvetica Std 35 Thin"', 'sans-serif'],
            'sans-normal': ['"Neue Helvetica Std 55 Roman"', 'sans-serif'],
            'sans-italic': ['"Neue Helvetica Std 56 Italic"', 'sans-serif'],
            'sans-bold': ['"Neue Helvetica Std 75 Bold"', 'sans-serif'],
            'sans-probold': ['"Neue Helvetica Std 75 Bold"', 'sans-serif'],
        },
        fontSize: {
            11: '.6875rem',
            12: '.75rem',
            13: '.8125rem',
            14: '.875rem',
            15: '.9375rem',
            16: '1rem',
            18: '1.125rem',
            19: `${19/16}rem`,
            20: '1.25rem',
            21: '1.3125rem',
            22: `${22/16}rem`,
            23: `${23/16}rem`,
            24: '1.5rem',
            26: '1.625rem',
            28: '1.75rem',
            30: '1.875rem',
            32: '2rem',
            36: '2.25rem',
            37: `${37/16}rem`,
            38: '2.375rem',
            40: `${40/16}rem`,
            44: '2.75rem',
            46: '2.875rem',
            48: '3rem',
            51: `${51/16}rem`,
            60: '3.75rem',
            70: `${70/16}rem`,
            80: '5rem',
            82: '5.125rem',
            132: '8.25rem',
        },
        height: {
            auto: 'auto',
            ...sizes,

            screen: '100vh'
        },
        inset: {
            '0': '0',
            bullet: '.325em',
            4: rem(4),
            8: rem(8),
            auto: 'auto',
            full: '100%',
        },
        letterSpacing: {
            tight: '-0.01433333333em',
            normal: '0',
            body: '0.015em',
            wide: '0.02307692308em',
            loose: '0.042em',
        },
        lineHeight: {
            // Titles

            tight: 0.95,
            none: 1,

            // Accident?
            // tight: 0.8333333333,

            // Leadin, etc
            normal: 1.2307692308,
            // Paragraph Text
            body: 1.5,
        },
        margin: (theme, {negative}) => ({
            auto: 'auto',
            ...spacing,
            ...marginSpacing,
            ...negative(spacing),
        }),
        maxHeight: {
            full: '100%',
            screen: '100vh',
            hero: '43rem',
            heroMedium: '36rem',
            heroSmall: '20.5rem',
            heroLarge: '59.375rem',
            filter: '35vh',
        },
        maxWidth: {
            ...sizes,
            screen: '100vw',
            container: '117rem',
            content: '88.125rem',
            xlContent: '102.5rem',
            sidebar: '25.75rem',
        },
        minHeight: {
            ...sizes,
            carousel: '400px',
            halfBlock: '28.125vw',
            full: '100%',
            screen: '100vh',
        },
        minWidth: {
            ...sizes,
        },
        opacity: {
            0: '0',
            5: '.05',
            10: '.10',
            15: '.15',
            25: '.25',
            50: '.5',
            75: '.75',
            90: '.90',
            100: '1',
        },
        padding: spacing,
        screens: {
            // Keep explicity min / max to play better with MediaQueries
            'mobileshelf': { max: '1024px' },
            xs: { min:'640px' },
            sm: { min:'768px' },
            md: { min:'1025px' },
            lg: { min:'1366px' },
            xl: { min:'1600px' },
        },
        stroke: {
            current: 'currentColor',
        },
        textColor: colors,
        width: {
            auto: 'auto',
            ...sizes,
            screen: '100vw'
        },
        zIndex: {
            auto: 'auto',
            0: 0,
            1: 1,
            2: 2,
            3: 3,
            4: 4,
            5: 5,
            10: 10,
            20: 20,
            30: 30,
            40: 40,
            50: 50,
            60: 60,
            70: 70,
            80: 80,
            90: 90,
            100: 100,
            101: 101,
            200: 200,
        },
    },
    variants: {
        aspectRatio: ['responsive'],
        backgroundColor: ['responsive', 'hover', 'group-hover', 'focus'],
        borderColor: ['responsive', 'hover', 'group-hover', 'focus', 'focus-mouse', 'focus-key'],
        fontStyle: ['responsive', 'hover', 'focus','group-hover'],
        fontSmoothing: ['responsive', 'hover', 'focus','group-hover'],
        opacity: ['responsive', 'hover', 'focus', 'group-hover'],
        outline: ['responsive', 'focus', 'focus-mouse'],
        textColor: ['responsive', 'hover', 'group-hover', 'focus'],
        textDecoration: ['responsive', 'hover', 'group-hover', 'focus'],
        textTransform: ['responsive', 'hover', 'focus','group-hover'],
    },
    plugins: [
        // ASPECT RATIO:
        function({theme, addUtilities, variants}) {
            const base = {
                height: '0',
                width: '100%',
                position: 'relative',
                overflow: 'hidden',
            };

            addUtilities({
                '.ar-base': base
            });

            Object.entries(theme('aspectRatio')).forEach(([key, value]) => {
                addUtilities({
                    [`.ar-${key}`]: {
                        ...base,
                        paddingBottom: `${100 / (value)}%`,
                    }
                }, {
                    variants: variants('aspectRatio')
                });
            });
        },

        function({theme, addUtilities, variants}) {
            addUtilities({
                '.flickity-is-enabled::after': {
                    content: `'flickity'`,
                    display: 'none',
                },
                '.flickity-is-disabled::after': {
                    content: `''`,
                    display: 'none',
                },
            }, {
                variants: ['responsive']
            });
        },

        function ({ addUtilities, config }) {

            const utils = {
                '.scrollsnap': {
                    'overflow': 'auto',
                    '-webkit-overflow-scrolling': 'touch',
                    'scroll-snap-type': 'x mandatory',
                },
                '.scrollsnap-none': {
                    'scroll-snap-type': 'none',
                },
                '.scrollsnap-start': {
                    'scroll-snap-align': 'start'
                },
                '.scrollsnap-center': {
                    'scroll-snap-align': 'center'
                },
                '.scrollsnap-end': {
                    'scroll-snap-align': 'end'
                },
            };

            const sizes = config('padding', []);

            Object.entries( sizes ).forEach( ([ key, size ]) => {

                utils[`.scroll-pl-${key}`] = {
                    ['scroll-padding-left']: size
                };
                utils[`.scroll-pr-${key}`] = {
                    ['scroll-padding-right']: size
                };
                utils[`.scroll-pt-${key}`] = {
                    ['scroll-padding-top']: size
                };
                utils[`.scroll-pbottom-${key}`] = {
                    ['scroll-padding-bottom']: size
                };


            });

            addUtilities( utils, ['responsive'] );

        },
        function ({ addUtilities, config }) {

            const sizes = config('maxWidth', []);

            const utils = {}

            Object.entries( sizes ).forEach( ([ key, size ]) => {

                const rule = `calc(50vw + (${size}/2))`;

                utils[`.offset-ml-${key}`] = {
                    ['margin-right']: rule
                };
            });



            addUtilities( utils, ['responsive'] );

        },
        function ({ addUtilities, config }) {

            const colors = config('colors', []);


            const utils = {}

            Object.entries( colors ).forEach( ([ key, color ]) => {
                utils[`.underline-${key}`] = {
                    ['text-decoration-color']: color
                };
            });

            Object.entries( colors ).forEach( ([ key, color ]) => {
                utils[`.links-${key} a`] = {
                    color
                };
                utils[`.hover:links-${key} a:hover`] = {
                    color
                };
            });

            addUtilities( utils, ['hover', 'responsive'] );

        },
        function ({ addVariant }) {
            addVariant('focus-mouse', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `[data-whatintent='mouse'] .focus-mouse\\${separator}${className}:focus`;
                });
            });
            addVariant('focus-key', ({ modifySelectors, separator }) => {
                modifySelectors(({ className }) => {
                    return `[data-whatintent='keyboard'] .focus-key\\${separator}${className}:focus`;
                });
            });
        }
    ],
}
