import Flickity from 'flickity';
import "flickity/css/flickity.css";
import "@css/components/flickity-overrides.scss";
import Vue from "vue";
import VueStash from "vue-stash";
import AppOverlay from "@/components/AppOverlay.vue";

import {
	qsa,
	docReady
} from "@/libs/helpers";


const getTarget = ( image ) => {
	let src = image.getAttribute('data-gallery');
	if ( !src && image.tagName === "IMG" ) {
		src = image.src;
	}

	if ( src ) {
		return src;
	}

	return false;
};

docReady(() => {

	const fns = [];

	const vueOverlay = document.getElementById('vue-overlay');

	const store = {
		open: false,
		trigger: false,
		cells: [],
	};

	qsa('[data-gallery]', (image,i) => {
		const src = getTarget(image);
		const caption = image.getAttribute('data-caption');

		if ( src ) {
			store.cells.push({ i, src, caption });
		}
	});

	if ( vueOverlay && store.cells.length ) {

		Vue.use(VueStash);
		const vm = new Vue({
			el: vueOverlay,
			data: {
				store,
				namespace: vueOverlay.id
			},
			render: h => h(AppOverlay),
		});

		qsa('[data-trigger-zoom]', button => {
			button.addEventListener('click', e => {
				e.preventDefault();
				store.trigger = button.getAttribute('data-trigger-zoom');
				store.open = true;


				// vm.$emit('open');
			});
		});
	}

	qsa('[data-hero-carousel]', list => {

		let flkty;

		if ( list.querySelectorAll('[data-slide]').length <= 1 ) {
			return;
		}


		const init = () => {
			flkty = new Flickity(list, {
				cellSelector: '[data-slide]',
				prevNextButtons: true,
				pageDots: false,
				adaptiveHeight: false,
				wrapAround: true,
				setGallerySize: false,
				arrowShape: {
					x0: 10,
					x1: 60, y1: 50,
					x2: 70, y2: 40,
					x3: 30
				}
			});


			const zoomButton = list.querySelector('[data-trigger-zoom]');

			const checkTrigger = () => {
				const img = flkty.selectedCell.element.querySelector('[data-gallery]');
				if ( img ) {
					const src = getTarget(img);
					if ( src ) {
						zoomButton.setAttribute('data-trigger-zoom', src );
					}
				}
			};

			checkTrigger();
			flkty.on('select', e => {
				checkTrigger();
			});
		};

		fns.push( init );
	});

	fns.forEach(fn => fn());
	window.addEventListener('resize.end', () => fns.forEach(fn => fn()));

});
