<template>
    <div class="flex w-full min-h-full px-4 pb-8 md:w-c6 lg:w-c3">
        <div data-link-cover class="flex flex-col self-stretch w-full h-auto bg-white shadow">
            <div class="flex-grow-0 flex-shrink-0 w-full my-0 border-b-4 border-berry">
                <div class="ar-33x19">
                    <div class="absolute inset-0 group-hover:opacity-75">
                        <img v-if="image" :src="image.url"
                             class="object-cover w-full h-full backface-hidden lazyload" />
                    </div>
                    <div v-if="this.entry.typeHandle === 'opinion'" class="absolute right-0 p-2 text-white uppercase bg-berry text-11">Opinion piece</div>

                </div>
            </div>
            <div class="flex flex-col flex-grow p-5 text-charcoal">
                <a :href="entry.url" class="text-black no-underline hover:underline text-14" data-link-target>
                    <div class="mb-2">
                        <span class="inline-block h-3 mr-2 w-6px bg-berry"></span>
                        <time :datetime="entry.postDate">
                            <strong>{{ postDateMonth }} {{ postDate }},</strong>
                            {{ postDateYear }} {{ entryType }}
                        </time>
                    </div>
                    <h3 class="mt-4 leading-normal text-22">{{ title }}</h3>
                </a>
            </div>
        </div>
	</div>
</template>

<script>
export default {
	props: ['entry'],
	computed: {
		image() {
			if ( this.entry.gridImage && this.entry.gridImage.length ) {
				return this.entry.gridImage[0];
			}

			return false;
		},
		title() {
			return this.entry.title;
		},
        entryType() {
		    return this.entry.type;
        },
        postDateMonth() {
		    const month =  new Date(this.entry.postDate).getMonth()
            let options = { month: 'long'};
		    const monthName = new Intl.DateTimeFormat('en-US', {
		        month: 'long',
		    }).format(month);
            return monthName;
        },
        postDate() {
            return new Date(this.entry.postDate).getDate();
        },
        postDateYear() {
            return new Date(this.entry.postDate).getFullYear();
        }
	}
}
</script>

<style>

</style>


