
// CSS
import "@css/app.scss";

import 'what-input';

// Polyfills
import "@/libs/polyfills";
import "@/libs/object-fit";

// Our App
import { initResizeEvents } from "@/libs/helpers";
import { initHeader } from "@/parts/sticky-header";

import "@/parts/lazyloading";
import "@/parts/navigation";
import "@/parts/accessibility";
import "@/parts/modals";
import "@/parts/inline-carousel";
import "@/parts/hero-carousel";
import "@/parts/project-filters";
import "@/parts/news-filters";
import "@/parts/share-widget";
import "@/parts/exposure-gallery";
import "@/parts/hero-slideshow";
import "@/parts/mailchimp";
import "@/parts/video-cover";

initResizeEvents();
initHeader();

// A fully merged version of the Tailwind configuration object:
import resolveConfig from "tailwindcss/resolveConfig";
import tailwind from "../../tailwind.config.js";
const APP = window.APP || {};
APP.tailwind = resolveConfig(tailwind);
