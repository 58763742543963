<template>
    <div class="fixed inset-0 z-200 bg-berry-95 transition-alpha"
         :class="{
            'invisible': !visible,
        }">
        <focus-trap class="absolute inset-0 flex items-center justify-center p-6"
                    id="vue-overlay"
                    :disabled="!visible">

            <!-- Dismiss Zone -->
            <div class="absolute inset-0 z-0 cursor-pointer no-mouse-focus" @click="dismiss"></div>

            <div
                class="relative z-0 flex items-center justify-center w-auto h-auto max-h-full overflow-auto scrolling-touch no-overscroll">
                <!-- Modal will go into this...but there's no styling, do this on the modal container...unless you want a global style... -->

                <div v-if="loading" class="relative p-2 m-auto loader z-1 h-100" title="0">
                    <h1 class="text-white uppercase font-sans-thin text-30">Loading...</h1>
                    <svg version="1.1" class="mx-auto mt-2" id="loader-1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                         width="40px" height="40px" viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
  <path opacity="0.2" fill="#000" d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z"/>
                        <path fill="#000" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
    C22.32,8.481,24.301,9.057,26.013,10.047z">
    <animateTransform attributeType="xml"
                      attributeName="transform"
                      type="rotate"
                      from="0 20 20"
                      to="360 20 20"
                      dur="0.5s"
                      repeatCount="indefinite"/>
                        </path>
                    </svg>
                </div>

                <div class="relative m-auto">
                    <div :class="{
                            'hidden': loading,
                         }"
                         class="relative px-4 py-12 m-auto bg-white z-1" ref="content">
                    </div>
                    <icon-button icon="close"
                                 @click="dismiss"
                                 class="top-0 right-0 mt-4 mr-4 border no-mouse-focus z-1 hover:text-berry-dark hover:border-berry-dark"
                                :class="{
                                    'absolute text-berry border-berry': !loading,
                                    'fixed text-white border-white': loading,
                                }"
                    >
                        Dismiss Overlay
                    </icon-button>
                </div>
            </div>

        </focus-trap>
    </div>
</template>

<script>
    import FocusTrap from "@/components/generic/FocusTrap.vue";
    import IconButton from "@/components/generic/IconButton.vue";

    export default {
        components: {
            FocusTrap,
            IconButton,
        },
        computed: {
            visible() {
                return this.$root.visible;
            },
            content() {
                return this.$root.content;
            },
            loading() {
                return this.$root.loading;
            },
        },
        methods: {
            onClose( e ) {
                if ( this.visible && e.key === 'Escape' ) {
                    this.dismiss();
                }
            },
            dismiss() {
                this.$root.visible = false;
                // This is a bad coupling with the parent, but here we are...
                history.pushState( this.$root.initialState, this.$root.dismissTitle, this.$root.dismissUrl );
            }
        },
        watch: {
            content( newValue ) {
                if ( newValue ) {
                    this.$refs.content.innerHTML = newValue;
                    // add any event listeners for dismissers...
                    this.$nextTick( () => {
                        const buttons = this.$refs.content.querySelectorAll('button[data-dismiss]');
                        buttons.forEach( b => {
                            const remove = () => {
                                this.dismiss();
                                b.removeEventListener('click', remove );
                            };
                            b.addEventListener('click', remove );
                        });
                    });
                } else {
                    this.$refs.content.innerHTML = '';
                }
            },
            visible( newValue ) {
                if ( newValue ) {
                    document.body.style.overflow = 'hidden';
                } else {
                    document.body.style.overflow = 'visible';
                }
            },
        },
        mounted() {
            window.addEventListener('keydown', this.onClose );
        },
        beforeDestroy() {
            window.removeEventListener('keydown', this.onClose );
        },
    }
</script>

<style scoped lang="scss">
    .transition-alpha {
        transition: opacity .3s ease;

        &.invisible {
            opacity: 0;
            transition: opacity .3s ease, visibility 0s linear .3s;
        }
    }

    .loader {
        text-align: center;
        padding: 1em;
        display: inline-block;
        vertical-align: top;
    }

    /*
      Set the color of the icon
    */
    svg path,
    svg rect{
        fill: #ffffff;
    }
</style>
