import standardCarousel from "@/libs/standard-carousel"
import { qsa, docReady } from "@/libs/helpers";

const getData = (el, attr) => {
    return el.getAttribute(attr);
};

docReady(() => {
    const initFns = [];

	qsa('[data-carousel]', el => {
        let flkty;
        const breakpoint = getData(el, 'data-breakpoint');
        // Scrape flickity options from data attr.
        const dataOptionsText = getData(el, 'data-config');
        let options = false;
        const id = el.id;

        if (dataOptionsText) {
            options = JSON.parse(dataOptionsText);
        }

		const init = () => {
            if (flkty && !breakpoint) {
                // Flkty already initialised, no breakpoint, no need to restore, just return.
                return;
            }

            if (flkty) {
                // If init is happening again, kill flkty to rebuild.
                flkty.destroy();
            }

            if (breakpoint && !media.matches(breakpoint)) {
                // Breakpoint doesn't match, and since flickity was destroyed
                // this means we can exit with no flickity initalised.
                return;
            }

            // Flickity is now initialised with the options.
            flkty = standardCarousel(el, '[data-cell]', options);

            // Custom UI elements
            qsa(`[data-prev="${id}"]`, prev => {
                prev.addEventListener('click', e => {
                    if (flkty) {
                        e.preventDefault();
                        flkty.previous();
                    }
                });
            });

            qsa(`[data-next="${id}"]`, next => {
                next.addEventListener('click', e => {
                    if (flkty) {
                        e.preventDefault();
                        flkty.next();
                    }
                });
            });
		};

        // Store all our initalisation functions...
        initFns.push(init);
    });

    // ...to be executed in one go...
    const initAll = () => {
        initFns.forEach(fn => fn());
    };

    // ...once on load
    initAll();

    // ...and altogether on a window resize.
    window.addEventListener('resize', initAll);
});
