var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flex w-full min-h-full px-4 pb-8 md:w-c6 lg:w-c3" },
    [
      _c(
        "div",
        {
          staticClass:
            "flex flex-col self-stretch w-full h-auto bg-white shadow",
          attrs: { "data-link-cover": "" }
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex-grow-0 flex-shrink-0 w-full my-0 border-b-4 border-berry"
            },
            [
              _c("div", { staticClass: "ar-33x19" }, [
                _c(
                  "div",
                  { staticClass: "absolute inset-0 group-hover:opacity-75" },
                  [
                    _vm.image
                      ? _c("img", {
                          staticClass:
                            "object-cover w-full h-full backface-hidden lazyload",
                          attrs: { src: _vm.image.url }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                this.entry.typeHandle === "opinion"
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "absolute right-0 p-2 text-white uppercase bg-berry text-11"
                      },
                      [_vm._v("Opinion piece")]
                    )
                  : _vm._e()
              ])
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex flex-col flex-grow p-5 text-charcoal" },
            [
              _c(
                "a",
                {
                  staticClass:
                    "text-black no-underline hover:underline text-14",
                  attrs: { href: _vm.entry.url, "data-link-target": "" }
                },
                [
                  _c("div", { staticClass: "mb-2" }, [
                    _c("span", {
                      staticClass: "inline-block h-3 mr-2 w-6px bg-berry"
                    }),
                    _vm._v(" "),
                    _c("time", { attrs: { datetime: _vm.entry.postDate } }, [
                      _c("strong", [
                        _vm._v(
                          _vm._s(_vm.postDateMonth) +
                            " " +
                            _vm._s(_vm.postDate) +
                            ","
                        )
                      ]),
                      _vm._v(
                        "\n                            " +
                          _vm._s(_vm.postDateYear) +
                          " " +
                          _vm._s(_vm.entryType) +
                          "\n                        "
                      )
                    ])
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticClass: "mt-4 leading-normal text-22" }, [
                    _vm._v(_vm._s(_vm.title))
                  ])
                ]
              )
            ]
          )
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }