
import { docReady, qsa } from "@/libs/helpers";
import { GlobalState } from "@/parts/global-state";
import { lockHeader, unlockHeader } from "@/parts/sticky-header";
import "@css/components/navigation.scss";

const lock = false;

const toggleAttr = ( el, attr ) => {
	const state = el.getAttribute( attr );
	const newState = state === "true" ? "false" : "true";
	el.setAttribute( attr, newState );
	return newState;
};

docReady(() => {

	const closeAll = () => {
		qsa('#nav .nav-a, #nav .nav-down', link => {
			link.setAttribute('data-state', 'closed');
			link.setAttribute('aria-expanded', 'false');
		});
	};

	const container = document.getElementById('nav-container');

	const searchButton = document.getElementById('search-toggle');
	const searchInput = document.getElementById('site-search');

	if ( searchButton ) {
		searchButton.addEventListener('click', () => {
			closeAll();
			const newState = toggleAttr( searchButton, 'aria-expanded');

			searchButton.setAttribute('aria-expanded', newState );

			// Don't focus the input if a keyboard user, as that would be really annoying.
			// Mouse users might find it better for inputs.
			const what = document.documentElement.getAttribute('data-whatinput');

			if ( newState === "true") {
				lockHeader();
			} else {
				unlockHeader();
			}

			if ( what === "mouse" && searchInput && newState === "true" ) {
				searchInput.focus();
			}
		});
	}

	const hamburger = document.getElementById('hamburger');
	if ( hamburger ) {
		hamburger.addEventListener('click', e => {
			const state = toggleAttr( hamburger, 'aria-pressed' );
			document.body.setAttribute( 'data-mobile-menu-visible', state );
			if ( state === "true" ) {
				lockHeader();
			} else {
				unlockHeader();
			}
		});
	}

	if ( lock ) {
		return;
	}

	qsa('#nav .nav-li', (li, i) => {

		const link = li.querySelector('.nav-a');
		const btn = li.querySelector('.nav-down');
		const shelf = li.querySelector('.nav-reveal');

		// Data State set by mouse,
		// do not set aria labels, as that
		// would confuse the state for a kb / blind
		// user....
		let timer;

		let lockTimer;

		const lockAnimation = () => {
			clearTimeout( lockTimer );
			lockTimer = setTimeout( () => container.classList.add('lock-transition'), 50 );
		};

		const unlockAnimation = () => {
			clearTimeout( lockTimer );
			console.log("Unlock");
			container.classList.remove('lock-transition');
		};

		const openShelf = () => {
			clearTimeout(timer);
			console.log("Mouseover");
			timer = setTimeout( () => {
				closeAll();
				lockAnimation();
				link.setAttribute('data-state', 'open');
			}, 500 );
		};

		link.addEventListener('mouseover', openShelf );
		shelf.addEventListener('mouseover', openShelf );

		const closeShelf = () => {

			clearTimeout(timer);
			console.log("Mouseout");
			timer = setTimeout(() => {
				console.log("Mouseout Trigger");
				unlockAnimation();
				closeAll();
			}, 250);
		};

		link.addEventListener('mouseout', closeShelf );
		shelf.addEventListener('mouseout', closeShelf );

		// For keyboard users, install the 'down' button,
		// so that this can toggle the menu as a separate
		// focusable to the main item, which is a link.

		// Toggle the menu on a click of this button...
		// Note that since hover is handled separately,
		// then clicking while hovered will not appear to
		// do anything as the hover will override.
		btn.addEventListener('click', e => {

			const state = link.getAttribute('data-state');
			const expanded = link.getAttribute('aria-expanded');

			// Close everything else...
			closeAll();

			if ( state === "open" || expanded === "true" ) {
				link.setAttribute('data-state', 'closed');
				link.setAttribute('aria-expanded', 'false');
				btn.setAttribute('aria-expanded', 'false');
				unlockAnimation();
			} else {
				link.setAttribute('data-state', 'open');
				btn.setAttribute('aria-expanded', 'true');
				link.setAttribute('aria-expanded', 'true');
				lockAnimation();
			}
			e.preventDefault();

		});
	});

});
