var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.runOnce || _vm.shadowTopic || _vm.shadowAuthor
    ? _c("div", { attrs: { id: "news-results-list" } }, [
        _vm.query || _vm.shadowTopic || _vm.shadowAuthor
          ? _c(
              "div",
              {
                staticClass:
                  "flex pb-2 mt-1 border-b-2 md:pt-15 lg:pt-12 pt-3px border-berry"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "font-sans-bold",
                    class: {
                      "lg:text-60 text-48":
                        !_vm.query || !_vm.shadowTopic || !_vm.shadowAuthor,
                      "lg:text-48 text-36":
                        (_vm.query && _vm.shadowTopic) ||
                        (_vm.query && _vm.shadowAuthor)
                    }
                  },
                  [
                    _vm.query
                      ? _c("span", { staticClass: "inline-block" }, [
                          _c("span", { staticClass: "text-grey" }, [
                            _vm._v("Searched for")
                          ]),
                          _vm._v(" "),
                          _c("em", [_vm._v("'" + _vm._s(_vm.query) + "'")]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              class: _vm.buttonClass,
                              on: {
                                click: function($event) {
                                  _vm.query = ""
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "visually-hidden" }, [
                                _vm._v("Clear Search Query")
                              ]),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "block w-2 h-2 pointer-events-none fill-current svgfull",
                                  attrs: {
                                    focusable: "false",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#svg-close" }
                                  })
                                ]
                              )
                            ]
                          ),
                          _vm._v(" "),
                          _vm.shadowTopic
                            ? _c("span", { staticClass: "text-grey" }, [
                                _vm._v("in")
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.shadowAuthor
                            ? _c("span", { staticClass: "text-grey" }, [
                                _vm._v("by")
                              ])
                            : _vm._e()
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shadowTopic
                      ? _c("span", { staticClass: "inline-block" }, [
                          _c("span", [_vm._v(_vm._s(_vm.shadowTopic.title))]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              class: _vm.buttonClass,
                              on: {
                                click: function($event) {
                                  _vm.topic = false
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "visually-hidden" }, [
                                _vm._v("Clear Topic Filter")
                              ]),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "block w-2 h-2 pointer-events-none fill-current svgfull",
                                  attrs: {
                                    focusable: "false",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#svg-close" }
                                  })
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.shadowAuthor
                      ? _c("span", { staticClass: "inline-block" }, [
                          _c("span", [_vm._v(_vm._s(_vm.shadowAuthor.title))]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              class: _vm.buttonClass,
                              on: {
                                click: function($event) {
                                  _vm.author = false
                                }
                              }
                            },
                            [
                              _c("span", { staticClass: "visually-hidden" }, [
                                _vm._v("Clear Author Filter")
                              ]),
                              _vm._v(" "),
                              _c(
                                "svg",
                                {
                                  staticClass:
                                    "block w-2 h-2 pointer-events-none fill-current svgfull",
                                  attrs: {
                                    focusable: "false",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("use", {
                                    attrs: { "xlink:href": "#svg-close" }
                                  })
                                ]
                              )
                            ]
                          )
                        ])
                      : _vm._e()
                  ]
                )
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "relative" },
          [
            _c(
              "loading-results",
              { attrs: { loading: _vm.loading } },
              [
                _vm.loading
                  ? [_vm._v("\n\t\t\t\t\tLoading News...\n\t\t\t\t")]
                  : [
                      _vm._v(
                        "\n\t\t\t\t\tFinished Loading. " +
                          _vm._s(_vm.news.length) +
                          " News Found.\n\t\t\t\t"
                      )
                    ]
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "flex flex-wrap pt-6 -mx-4" },
              [
                _vm._l(_vm.news, function(entry) {
                  return _c("news-preview", {
                    key: entry.id,
                    attrs: { entry: entry }
                  })
                }),
                _vm._v(" "),
                _vm.news.length === 0
                  ? _c("div", [
                      _c("div", { staticClass: "p-4" }, [
                        _vm._v("No news found. Try widening your search")
                      ])
                    ])
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }