import { docReady, qsa } from "@/libs/helpers";
import Vue from "vue";
import VueStash from "vue-stash";

import AppProjects from "@/components/AppProjects.vue";
import AppFilters from "@/components/AppFilters.vue";

Vue.use(VueStash);

const mount = () => {
	const filters = document.getElementById('vue-app-filters');
	const projects = document.getElementById('vue-app-projects');

	if (!(filters && projects)) {
		return;
	}

	const store = {
		projects: window.VM.projects,
        expertise: window.VM.expertise,
        services: window.VM.services,
		// Ensure string.
		category: window.VM.category,
		query: '',
		atoz: window.VM.atoz,
		get categoryId() {
			if (this.category) {
				return this.category.id;
			}

			return false;
		},
		loading: false,
	};

	// eslint-disable-next-line
	new Vue({
		el: filters,
		data: {
			store,
			namespace: filters.id
		},
		render: h => h(AppFilters),
	});

	// eslint-disable-next-line
	new Vue({
		el: projects,
		data: {
			store,
			namespace: projects.id
		},
		render: h => h(AppProjects),
	});
};

// Test the flash:
// window.mount = mount;

docReady(() => {
	mount();
});
