<template>
	<div class="focus-trap" ref="trap">
		<slot />
	</div>	
</template>

<script>

import focusTrap from 'focus-trap';

export default {
	props: {
		disabled: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		disabled() {
			if (this.disabled) {
				this.$trap.deactivate();
			} else {
				this.$trap.activate();
			}
		}
	},
	mounted() {
		this.$trap = focusTrap(this.$refs.trap, {
			escapeDeactivates: false,
			clickOutsideDeactivates: false,
			onActivate: () => {
				this.$emit( 'activate', this.$trap );
			},
			onDeactivate: () => {
				this.$emit( 'deactivate', this.$trap );
			}

		});	
			

		if ( this.disabled ) {
			this.$trap.deactivate();
		}
	}
}
</script>