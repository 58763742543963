import Flickity from "flickity";
import "flickity/css/flickity.css";
import "@css/components/flickity-overrides.scss";

export const standardCarousel = function (carousel, selector, options = {}) {
	const mergedOptions = Object.assign( {
        cellSelector: selector,
        cellAlign: 'left',
        prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: true,
        setGallerySize: true,
        groupCells: true,
		autoPlay: false,
        lazyLoad: 4,
		arrowShape: {
			x0: 20,
			x1: 50, y1: 20,
			x2: 80, y2: 40,
			x3: 80
        },
	}, options );

	return new Flickity(carousel, mergedOptions);
};

export default standardCarousel;
