var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.loading
      ? _c(
          "div",
          {
            staticClass:
              "absolute inset-0 bg-white-80 z-10 flex items-start py-28 px-6 md:px-14 justify-start"
          },
          [
            _c(
              "div",
              {
                staticClass: "font-sans-bold mt-1 text-20",
                attrs: { "aria-live": "assertive", role: "alert" }
              },
              [_vm._t("default")],
              2
            )
          ]
        )
      : _c(
          "div",
          {
            staticClass: "visually-hidden",
            attrs: { "aria-live": "assertive", role: "alert" }
          },
          [_vm._t("default")],
          2
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }